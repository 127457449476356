import React, { useEffect, useState } from "react";
import {
  FaGreaterThan,
  FaLessThan,
  FaUserAlt,
  FaUsersCog,
} from "react-icons/fa";
import commonService from "../../services/common.service";
// import ImageUtil from "../../utilities/ImageUtil";
import UserRegistrationPopup from "../UserRegistration/UserRegistrationPopup";

function FeedBack() {
  const visiblePages = 5;
  const feedbacksPerPage = 10;

  const [feedbacks, setFeedbacks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [hideRightArrow, sethideRideArrow] = useState(false);
  const [hideLeftArrow, sethideLeftArrow] = useState(true);

  const adjustPages = () => {
    let tempStart = 1;
    let tempEnd = Math.min(5, totalPages);

    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };

  useEffect(() => {
    adjustPages();
  }, [currentPage]);

  const paginate = async (currPage) => {
    let result;
    result = await commonService.getFeeback(feedbacksPerPage, currPage);

    setFeedbacks(result.results);
    setCurrentPage(currPage);

    if (currPage == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }

    if (currPage == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
  };

  const handleNextPage = async () => {
    if (currentPage + 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }

    if (currentPage + 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      const result = await commonService.getFeeback(
        feedbacksPerPage,
        currentPage + 1
      );

      setFeedbacks(result.results);
      setCurrentPage(currentPage + 1);
    } else if (totalPages > pageNumbers[4]) {
      let tempPageArr = [];
      const endPage = Math.min(totalPages, pageNumbers[4] + 4);

      for (let page = pageNumbers[4] + 1; page <= endPage; page++) {
        tempPageArr.push(page);
      }

      const result = await commonService.getFeeback(
        feedbacksPerPage,
        currentPage + 1
      );

      setFeedbacks(result.results);
      setCurrentPage(currentPage + 1);
      setPageNumbers(tempPageArr);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage - 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }

    if (currentPage - 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }

    if (currentPage > pageNumbers[0]) {
      const result = await commonService.getFeeback(
        feedbacksPerPage,
        currentPage - 1
      );

      setFeedbacks(result.results);
      setCurrentPage(currentPage - 1);
    } else if (currentPage > 1) {
      let tempPageArr = [];
      for (let page = pageNumbers[0] - 5; page <= pageNumbers[0] - 1; page++) {
        tempPageArr.push(page);
      }

      const result = await commonService.getFeeback(
        feedbacksPerPage,
        currentPage - 1
      );

      setFeedbacks(result.results);
      setCurrentPage(currentPage - 1);
      setPageNumbers(tempPageArr);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  const getFeedbacks = async () => {
    const result = await commonService.getFeeback(feedbacksPerPage, 1);

    if (result.totalResults < feedbacksPerPage) {
      setPageNumbers([1]);
      sethideRideArrow(true);
    } else {
      let tempTotalPages = Math.max(
        1,
        Math.ceil(result.totalResults / feedbacksPerPage)
      );
      setTotalPages(tempTotalPages);

      if (tempTotalPages <= 1) {
        sethideRideArrow(true);
      } else {
        sethideRideArrow(false);
      }

      if (tempTotalPages > 5) {
        tempTotalPages = 5;
      }

      let tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }

      setPageNumbers(tempPageArr);
    }

    setCurrentPage(1);
    sethideLeftArrow(true);
    setFeedbacks(result.results);
  };

  return (
    <div className="d-flex feedback-container flex-column">
      <div className="feedback-outer mx-auto">
        <div className="feedback-inner mt-3">
          <div className="indicator-container">
            <div>
              <FaUsersCog className="icon" />
            </div>

            <div className="text">Feedbacks</div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th key="recipients">Email</th>
                  <th key="subject">Feedback</th>
                  <th key="date">Created Date</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {feedbacks.map((feedback, index) => {
                  const utcDateString = feedback.createdAt;
                  const date = new Date(utcDateString);
                  const istDateString = date.toLocaleString("en-IN");
                  feedback.createdDate = istDateString;

                  return (
                    <tr key={index}>
                      <td>{feedback.email}</td>

                      <td>{feedback.feedback}</td>

                      <td className="date-column">
                        {(() => {
                          const inputDate = feedback.createdAt;
                          if (inputDate) {
                            const date = new Date(inputDate);
                            const year = date.getFullYear();
                            const month = String(date.getMonth() + 1).padStart(
                              2,
                              "0"
                            );
                            const day = String(date.getDate()).padStart(2, "0");
                            const formattedDate = `${day}/${month}/${year}`;
                            return formattedDate;
                          } else {
                            return "N/A";
                          }
                        })()}
                      </td>

                      <td
                        onClick={() => {
                          setSelectedDetails({
                            ...feedback,
                            createdDate: istDateString,
                          });
                          // console.log("Hi");
                          // console.log("Detailssss---",selectedDetails.feedbackImage)
                          setShowDetails(!showDetails);
                        }}
                      >
                        <FaUserAlt className="user-icon" style={{ color: "#175572" }} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {showDetails ? (
            <UserRegistrationPopup
              toggleBtn={showDetails}
              toggleFunction={setShowDetails}
            >
              <div className="d-flex">
                <p className="labels">Email:</p>
                <p className="values">{selectedDetails.email}</p>
              </div>

              <div className="d-flex">
                <p className="labels">Feedback:</p>
                <p className="values">{selectedDetails.feedback}</p>
              </div>

              <div className="d-flex">
                <p className="labels">Created Date:</p>
                <p className="values">{selectedDetails.createdDate}</p>
              </div>

              {selectedDetails.feedbackImage &&
                selectedDetails.feedbackImage !== "undefined" && (
                  <div className="d-flex">
                    <p className="labels">Feedback Image:</p>
                    <img
                      className="values"
                      height="200px"
                      width="200px"
                      // src={ImageUtil.transform(selectedDetails.feedbackImage)}
                      src={selectedDetails.feedbackImage}
                      alt="Feedback Image"
                    />
                  </div>
                )}
            </UserRegistrationPopup>
          ) : null}

          <div className="pagination my-4">
            {!hideLeftArrow ? (
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className={currentPage === 1 ? "active" : ""}
              >
                <FaLessThan
                  className="arrow"
                  style={{
                    marginBottom: "4px",
                    marginRight: "2px",
                    padding: "1px",
                  }}
                />
              </button>
            ) : (
              <></>
            )}

            {pageNumbers.map((page) => (
              <button
                key={page}
                disabled={currentPage === page}
                className={currentPage === page ? "active" : ""}
                onClick={() => {
                  paginate(page);
                }}
              >
                {page}
              </button>
            ))}

            {!hideRightArrow ? (
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={currentPage === totalPages ? "active" : ""}
              >
                <FaGreaterThan
                  className="arrow"
                  style={{
                    marginBottom: "4px",
                    marginLeft: "2px",
                    padding: "1px",
                  }}
                />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedBack;
