import React, { useRef, useState } from 'react'
import ImageSelector from './ImageSelector'
import axios from '../../config/axios'

function SocialTemplateBank() {
  const childRef = useRef()
  return (
    <ImageSelector ref={childRef}/>
  )
}

export default SocialTemplateBank;