import React from "react";
import { Card, CardBody } from "../../components/Card/Card";
import { Link } from "react-router-dom";
import utilities from "./EssData.js";

function Assist() {
  return (
    <div className="ess">
      <div
        className="d-flex flex-wrap ms-4"
        style={{ position: "relative", zIndex: "0", paddingTop: "1.5rem" }}
      >
        {utilities.map((utility, index) => {
          return (
            <div
              className="card-pos px-3 my-2"
              key={index}
              style={{ paddingBottom: "1rem", flexBasis: "25%" }}
            >
              <Link to={utility.link} className="utility-link">
                <Card>
                  <CardBody>
                    <h5 className="utility-title">{utility.title}</h5>
                    <span className="utility-img">
                      <img src={utility.img} alt={utility.title} />
                    </span>
                  </CardBody>
                </Card>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Assist;
