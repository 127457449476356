import React,{useEffect, useState} from "react";
import Option1 from "../Option1/Option1";
import Option2 from "../Option2/Option2";
import { useDispatch, useSelector } from "react-redux";
import { setSkillsList } from "../../features/skillSlice";

const OptionParent = (skill) => {
    let skillsList = useSelector((state)=>state.skills.skillsList)
    const [final, setFinal] = useState("");
    const [skills, setskills] = useState(skill.skill);
    let dispatch = new useDispatch()
 
    const handleInputChange = (event) => {
        const value = event.target.value;
        setFinal(value);
    };
    const handleSkillChange = (skills1) =>{
        dispatch(setSkillsList(skills1))
       
    }
    return(
        <>
        <div className="tab-content">
            <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                <Option1 skill={skillsList} final={final} setFinal={setFinal} handleInputChange={handleInputChange} handleSkillChange={handleSkillChange}/>
            </div>
            <div className="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
                <Option2 skill={skillsList} final={final} setFinal={setFinal} handleInputChange={handleInputChange} handleSkillChange={handleSkillChange} />
            </div>
        </div>
        </>
    )
}

export default OptionParent;