import React from "react";

function StatusCard(props) {
  const { text, status, buzzId, handleBuzzStatusUpdate } = props;
  let statusText = "";
  if (status === 0) statusText = "draft";
  else if (status === 1) statusText = "published";
  else statusText = "trash";

  return (
    <div
      className="status-card d-flex align-items-center justify-content-center pt-3"
      style={
        text === statusText ? { backgroundColor: "#26c6ea", color: "#fff" } : {}
      }
      onClick={() => handleBuzzStatusUpdate(buzzId, text)}
    >
      <p>{text}</p>
    </div>
  );
}

export default StatusCard;
