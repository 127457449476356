import TokenService from "./token.service";
import axios from "../config/axios";
import alt_axios from "axios";

// dummy data for testing purpose.

class PhotoSharingMethods {
  getAllEvents = async (folderName = "",pageNo = 1, name) => {
    let url = `/memories/showMemoryStructure${folderName}/?limit=20&page=${pageNo}&sortBy=createdAt:desc`
    if(name){
      url = url + `&name=${name}`
    }
    const accessToken = TokenService.getLocalAccessToken();
    const response = await axios.get(
     url,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  };
  getUnfilteresEvents = async (folderName = "") => {
    
    const accessToken = TokenService.getLocalAccessToken();
    folderName += "/";
    const response = await axios.get(
      `/memories/showMemoryStructure${folderName}?limit=1000&sortBy=createdAt:desc`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  };
  getAllEvents1 = async (folderName = "") => {
    
    const accessToken = TokenService.getLocalAccessToken();
    folderName += "/";
    const response = await axios.get(
      `/memories/showMemoryStructure${folderName}?limit=1000&sortBy=createdAt:desc`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  };

  uploadImage = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/memories/uploadMemory`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };

  manageLike = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/memories/like`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };

  deleteMemory = async (id) => {
    const response = await alt_axios.delete(
      `https://${process.env.REACT_APP_API_URL}/memories/${id}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };

  createAlbum = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/memories/createAlbum`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response;
  };
  renameAlbum = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/memories/renameMemory`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
}

export default new PhotoSharingMethods();
