import React from "react";

function GradientBorderButton(props) {
  const {
    text,
    icon,
    clickHandler,
    additionalClass,
    outerDivClass,
    innerDivClass,
    iconAdditionalClass,
  } = props;

  return (
    <div
      className={`upload-btn ${
        additionalClass === undefined ? "" : additionalClass
      }`}
      onClick={clickHandler}
    >
      <div className={`outer-div ${outerDivClass}`}>
        <div className={`inner-div ${innerDivClass}`}>
          {icon}
          {icon && <span className={iconAdditionalClass}></span>}
          {text}
        </div>
      </div>
    </div>
  );
}

export default GradientBorderButton;
