import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Content from "./components/Content/Content";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { AppSettings } from "./config/app-setting";
import { DOMStatesProvider } from "./context/DOMStatesContext";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/UserRegistration/ForgotPassword";

function App() {
  const defaultOptions = {
    appHeaderNone: false,
    appSidebarNone: false,
    appContentNone: false,
    appContentClass: "",
  };

  const navigate = useNavigate();

  const [appHeaderNone, setAppHeaderNone] = useState(
    defaultOptions.appHeaderNone
  );
  const [appSidebarNone, setAppSidebarNone] = useState(
    defaultOptions.appSidebarNone
  );
  const [appContentNone, setAppContentNone] = useState(
    defaultOptions.appContentNone
  );
  const [appContentClass, setAppContentClass] = useState();
  const [pathBeforeLogin, setPathBeforeLogin] = useState(
    localStorage.getItem("pathBeforeLogin") || ""
  );

  const user = useSelector((state) => state.user.user);

  // Set pathBeforeLogin in localStorage only if it's not already set
  useEffect(() => {
    if (
      !pathBeforeLogin &&
      window.location.pathname !== "/auth/login" &&
      window.location.pathname !== "/auth/forgot-password"
    ) {
      const currentPath = window.location.pathname;
      localStorage.setItem("pathBeforeLogin", currentPath);
      setPathBeforeLogin(currentPath);
    }
  }, [pathBeforeLogin]);

  // Login flow
  useEffect(() => {
    if (
      !user &&
      window.location.pathname !== "/auth/login" &&
      window.location.pathname !== "/auth/forgot-password"
    ) {
      navigate("/auth/login");
    }
    if (
      user &&
      (window.location.pathname === "/auth/login" ||
        window.location.pathname === "/auth/forgot-password")
    ) {
      const routeName = localStorage.getItem("pathBeforeLogin") || "/dashboard";
      navigate(routeName);
    }
  }, [user, pathBeforeLogin]);

  const providerValue = {
    setAppHeaderNone,
    setAppSidebarNone,
    setAppContentNone,
    setAppContentClass,
  };

  return (
    <AppSettings.Provider value={providerValue}>
      <DOMStatesProvider>
        {user ? (
          <div
            className={
              "app " +
              (appHeaderNone ? "app-without-header " : "") +
              (appSidebarNone ? "app-content-full-width " : "")
            }
          >
            {!appHeaderNone && <Header />}
            {!appSidebarNone && <Sidebar />}
            {!appContentNone && <Content />}
          </div>
        ) : window.location.pathname == "/auth/forgot-password" && !user ? (
          <ForgotPassword />
        ) : (
          <Login />
        )}
      </DOMStatesProvider>
    </AppSettings.Provider>
  );
}

export default App;
