import axios from "../config/axios";
import alt_axios from "axios";
import TokenService from "../services/token.service";

class BuzzService {
  getBuzzList = async (pageNo = 1, status = 1) => {
    const response = await axios.get(
      `/buzz/list?sortBy=-buzzNo&page=${pageNo}&status=${status}&limit=6`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  removeBuzz = async (buzzId) => {
    const response = await axios.delete(
      `/buzz/${buzzId}`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  getBuzzList1 = async (pageNo = 1) => {
    const response = await axios.get(
      `/buzz/list?sortBy=-buzzNo&status=1&page=${pageNo}&limit=3`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  getLatestBuzz = async (limit) => {
    const response = await axios.get(`/buzz/latestRelease/${limit}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  updateBuzzStatus = async (buzzId, status) => {
    const response = await axios.post(
      "/buzz/update",
      {
        buzzId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  updateBuzzLikedby = async (buzzId, likedBy) => {
    const response = await axios.post(
      "/buzz/update",
      {
        buzzId,
        likedBy,
      },
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  sendBuzzEmail = async (body) => {
    const response = await axios.post(
      "/buzz/mail",
      
        body,
     
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  updateBuzzComments = async (buzzId, comments) => {
    const response = await axios.post(
      "/buzz/update",
      {
        buzzId,
        comments,
      },
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  uploadBuzz = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/buzz/upload`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
}

export default new BuzzService();
