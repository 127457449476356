import React from "react";
import { BsFileEarmarkPdf } from "react-icons/bs";

function Result({ file1, file2, matching }) {
  return (
    <div>
      <BsFileEarmarkPdf className="result_icon" />
      <p
        className="justify-text font-weight-light"
        style={{ wordBreak: "break-all" }}
      >
        Score of {file2} is {matching}
      </p>
    </div>
  );
}

export default Result;
