import axios from "../config/axios";
import alt_axios from "axios";
import TokenService from "../services/token.service";

class DashboardService {
  getBulletinImages = async (date) => {
    const response = await axios.get(`/bulletinBoard?postScheduleDate=${date}&status=1`);
    return response.data.results;
  };

  uploadBulletinImage = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/bulletinBoard/createBulletin`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };

  deleteBulletinImage = async (id) => {
    const response = await axios.post(`/bulletinBoard/${id}`);
    return response.data;
  };

  // CRUD methods for notice board.

  getLatestNoticeBoard = async (date) => {
    const response = await axios.get(`/noticeBoard?date=${date}`);
    return response.data;
  };

  createNotice = async (data) => {
    const response = await axios.post("/noticeBoard/createNotice", data);
    return response.data;
  };

  deleteNotice = async (data) => {
    const response = await axios.post("/noticeBoard/deleteNotice", data);
    return response.data;
  };
  editNotice = async (data) => {
    const response = await axios.post("/noticeBoard/editNotice", data);
    return response.data;
  };

  // CRUD methods for notifications
  getNotifications = async (date) => {
    const response = await axios.get(`/notification?date=${date}`);
    return response.data;
  };

  createNotification = async (data) => {
    const response = await axios.post("/notification/createNotification", data);
    return response.data;
  };

  deleteNotification = async (data) => {
    const response = await axios.post("/notification/deleteNotification", data);
    return response.data;
  };
}

export default new DashboardService();
