import alt_axios from "axios";
import TokenService from "../services/token.service";

class ActivityLogs {
  getActivitylogs = async (
   
    userEmail,
    pageNo = 1,
    duration,
    start,
    end,
    department,
    shift,
    shiftTiming,
    moduleName,
    year
  ) => {
    // change the base url to REACT_APP_API_URL when deploying.
    let apiUrl = `https://${process.env.REACT_APP_API_URL}/activityLog?&limit=8`;
 

    if (duration) {
      apiUrl += `&duration=${duration}`;
    }

    if (duration == "custom") {
      if (start) {
        apiUrl += `&startDate=${start}`;
      }
      if (end) {
        apiUrl += `&endDate=${end}`;
      }
    }

    if (userEmail) {
      apiUrl += "&userEmail=" + userEmail;
    }

    if (department) {
      apiUrl += `&department=${department}`;
    }

    if (shift) {
      apiUrl += "&shift=" + shift;
    }

    if (shiftTiming) {
      apiUrl += "&shiftTiming=" + shiftTiming;
    }
    if(moduleName){
      apiUrl += "&moduleName=" + moduleName;
    }
    if(year){
      apiUrl += "&year=" + year;
    }
    const response = await alt_axios.get(apiUrl + "&page=" + pageNo, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };

  getActivitylogsForReport = async (
    userEmail,
    duration,
    start,
    end,
    department,
    shift,
    shiftTiming,
    moduleName,
    year
  ) => {
    let apiUrl = `https://${process.env.REACT_APP_API_URL}/activityLog/get?&limit=2000000`;

  
    if (duration) {
      apiUrl += `&duration=${duration}`;
    }

    if (duration == "custom") {
      if (start) {
        apiUrl += `&startDate=${start}`;
      }
      if (end) {
        apiUrl += `&endDate=${end}`;
      }
    }

    if (userEmail) {
      apiUrl += "&userEmail=" + userEmail;
    }

    if (department) {
      apiUrl += `&department=${department}`;
    }

    if (shift) {
      apiUrl += "&shift=" + shift;
    }

    if (shiftTiming) {
      apiUrl += "&shiftTiming=" + shiftTiming;
    }
    if(moduleName){
      apiUrl += "&moduleName=" + moduleName;
    }
    if(year){
      apiUrl += "&year=" + year;
    }
    const response = await alt_axios.get(apiUrl, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };
}

const activityLogsService = new ActivityLogs();

export default activityLogsService;
