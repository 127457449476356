import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },

    logout: (state) => {
      state.user = null;
      localStorage.clear();
    },

    refreshLogout: (state) => {
      state.user = null;

      // Removing individual items because we need to keep "pathBeforeLogin"
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        key !== "pathBeforeLogin" && localStorage.removeItem(key);
      }
    },
  },
});

export const { login, logout, refreshLogout } = userSlice.actions;

export default userSlice.reducer;
