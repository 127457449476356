import { createContext, useContext, useEffect, useReducer } from "react";
import reducer from "../reducer/DOMStatesReducer";

const DOMStatesContext = createContext();

const initialState = {
  showSidebar: false,
  showUploadForm: false,
  showMemoryImage: false,
  showMemoryUploadForm: false,
  showMemoryAlbumForm: false,
  showProfileDropdown: false,
  showProfileSidebar: false,
  showNotification: false,
  showNotificationForm: false,
};

const DOMStatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toggleShowSidebar = () => {
    dispatch({ type: "TOGGLE_SIDEBAR" });
  };

  const toggleShowNotification = () => {
    dispatch({ type: "SHOW_NOTIFICATION" });
  };
  const toggleShowNotificationForm = () => {
    dispatch({ type: "SHOW_NOTIFICATION_FORM" });
  };

  const toggleProfileSidebar = () => {
    dispatch({ type: "TOGGLE_PROFILE_SIDEBAR" });
  };
  const toggleFeedbackForm = () => {
    dispatch({ type: "TOGGLE_FEEDBACK_FORM" });
  };
  const toggleUploadForm = () => {
    dispatch({ type: "TOGGLE_UPLOAD_FORM" });
  };

  const toggleMemoryImage = () => {
    dispatch({ type: "TOGGLE_MEMORY_IMAGE" });
  };

  const toggleMemoryUploadForm = () => {
    dispatch({ type: "TOGGLE_MEMORY_UPLOAD_FORM" });
  };

  const toggleMemoryAlbumForm = () => {
    dispatch({ type: "TOGGLE_MEMORY_ALBUM_FORM" });
  };

  const toggleProfileDropdown = () => {
    const location = window.location.pathname;
    if (
      location.includes("buzz/admin") ||
      location.includes("photo-sharing") ||
      location.includes("social-template-bank")
    ) {
      dispatch({ type: "TOGGLE_PROFILE_DROPDOWN" });
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("/buzz/admin"))
      dispatch({ type: "TOGGLE_UPLOAD_BTN", payload: true });
  }, [window.location.pathname]);

  return (
    <DOMStatesContext.Provider
      value={{
        ...state,
        toggleShowSidebar,
        toggleUploadForm,
        toggleMemoryImage,
        toggleMemoryUploadForm,
        toggleMemoryAlbumForm,
        toggleProfileDropdown,
        toggleProfileSidebar,
        toggleShowNotification,
        toggleFeedbackForm,
        toggleShowNotificationForm,
      }}
    >
      {children}
    </DOMStatesContext.Provider>
  );
};

const useDOMStatesContext = () => {
  return useContext(DOMStatesContext);
};

export { DOMStatesProvider, useDOMStatesContext };
