import React, { useRef } from "react";
import skilldata from "../QueryGenerateBox/SkillData";
import tabledata from "../QueryGenerateBox/TableData";
import { useState } from "react";
import { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Toast } from "bootstrap";
import * as FiIcons from "react-icons/fi";
import Notification from "../Notification/Notification";
import { BsPlusCircle } from "react-icons/bs";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";

const Option1 = ({ skill, final, setFinal, handleInputChange, handleSkillChange }) => {
  let skillsList = useSelector((state)=>state.skills.skillsList)
  
  const [row, setRow] = useState([0]);
  const [column, setColumn] = useState([0]);
  const [output, setOutput] = useState([[""]]);
  const [success, setSuccess] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");

  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAddPopUp, setAddShowPopup] = useState(false)
  const skillRef = useRef()

  const [skillCount, setSkillCount] = useState(0);
  const [showDelete, setShowDelete] = useState(-1);
  const [skills, setSkills] = useState(skillsList)
  const [showPopUp, setShowPopup] = useState(-1);
  const [selectedText, setSelectedText] = useState('');
  const [selectedTextArray, setSelectedTextArray] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  const handleContextMenu = (e) => {
    e.preventDefault();
    const text = window.getSelection().toString();
    const { clientX, clientY } = e;
    setSelectedText(text);
    setShowAddButton(true);
    setButtonPosition({ top: clientY, left: clientX });
  };

  const handleAddText = () => {
    if (selectedText) {
      setSelectedTextArray([...selectedTextArray, selectedText]);
      setSelectedText('');
      setShowAddButton(false);
    }
  };
  const handleSkillSubmit = (skill) =>{
    
    if(!skill){
      setErrorNotific("Please enter the skill")
      setSuccessNotific("")

      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000);
      return
    }
   
    let data = skillsList.map(skill => skill.toLowerCase());
    let index = data.indexOf(skill.toLowerCase())
    
    if(index == -1){
      data.push(skill)
 
    handleSkillChange(data)
    setErrorNotific("")
setSuccessNotific("Skill added successfully")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000);
setAddShowPopup(false)

    } else {
setSuccessNotific("")
     
        setErrorNotific("Skill already exists")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000);
        return

      console.log("Skill already exists")
    }
   
  }
  // const [final, setFinal] = useState("");
  const handleCopy = async (e) => {
    setSuccess("Copied Successfully!")
    const toast1 = document.getElementById("toast-searchQuery");
    const toast = new Toast(toast1);
    toast.show();
  };
  const deleteSkill = (index) =>{
    let data = [...skillsList]
    data.splice(index, 1)

    handleSkillChange(data)
    setShowPopup(-1)
  }
  useEffect(() => {
    addRow();
    addColumn();
  }, []);

  const addRow = () => {
    let x = row.length;
    setRow((row) => [...row, x]);
  };

  const addColumn = () => {
    let x = column.length;
    setColumn((column) => [...column, x]);
    setOutput((output) => [...output, [""]]);
  };

  const Drop = (e, j, k) => {
    var x = e.dataTransfer.getData("text/html");
    e.target.value = x;
    let copy = [...output];
    copy[j][k] = x;
    setOutput(copy);
    let count = 0;

    for (let i = 0; i < copy.length; i++) {
      for (let j = 0; j < copy[i].length; j++) {
        if (copy[i][j] !== undefined && copy[i][j] !== null && copy[i][j] !== "") {
          count++;
        }
      }
    }
  
    setSkillCount(count)
    Output();
  };

  const Change = (e, j, k) => {
    let x = e.target.value;
    let copy = [...output];
    copy[j][k] = x;
    setOutput(copy);
    Output();
  };

  const DragStart = (e) => {
    e.dataTransfer.setData("text/html", e.target.value);
  };

  const Output = () => {
    const orArray = [];
    for (let i = 0; i < output.length; i++) {
      const andArray = [];
      for (let j = 0; j < output[i].length; j++) {
        if (output[i][j] != "" && output[i][j]!=undefined) {
          if(output[i][j].includes(" ")){
          andArray.push('"' + output[i][j] + '"');
          }
          else{
          andArray.push(output[i][j]);
          }
        }
      }
      if (andArray.length != 0) {
        const newOrArray = `(${andArray.join(" OR ")})`;
        orArray.push(newOrArray);
      }
    }
    const finalArray = `(${orArray.join(" AND ")})`;
    setFinal(finalArray);
  };

  return (
    <div className="option1 pt-1 w-100">
         {showPopUp != -1 ? (
          <div className="popup-container" >
            <div className="popup">
              <div className="popup-header">
                <span className="fs-6 fw-bold">Send Email</span>
                <button className="close-button" onClick={()=>{setShowPopup(-1)}}>
                  &#10005;
                </button>
              </div>
              
              <div className="popup-content mb-1">
                <h5>Are you sure you want to delete this skill from the database:"{skillsList[showPopUp]}"?</h5>
                <div className="d-flex justify-content-around my-1">
                <button color="primary" className="generate-btn" type="button" onClick={()=>{deleteSkill(showPopUp);}}>Yes</button><button className="generate-btn" type="button" onClick={()=>setShowPopup(-1)}>No</button>
              </div>
              </div>
          
            </div>
          </div>
        ) : null}
          {showAddPopUp ? (
          <div className="popup-container" >
            <div className="popup">
              <div className="popup-header">
                <span className="fs-6 fw-bold">Add skill</span>
                <button className="close-button" onClick={()=>{setAddShowPopup(false)}}>
                  &#10005;
                </button>
              </div>
              
              <div className="popup-content mb-1">
                <h5>Enter the skill you want to add to the main database:</h5>
                <input type="text" className="w-100 my-2" ref={skillRef} />
                <div className="d-flex justify-content-around my-1">
                <button color="primary" className="generate-btn  "  type="button" onClick={()=>{handleSkillSubmit(skillRef.current.value);}}>Submit</button><button className="generate-btn" type="button" onClick={()=>setAddShowPopup(false)}>Cancel</button>
              </div>
              </div>
          
            </div>
          </div>
        ) : null}
        {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
       <div className="toasts-container position-absolute mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-searchQuery">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              <p className="text-success">{success}</p>
              <p className="text-danger">{error}</p>
            </div>
          </div>
        </div>
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-between">
            <div>
              <label class="form-label">Skills</label>
            </div>
            {/* <div>
              <button className="skill-missing-btn">Skill Missing ?</button>
            </div> */}
          </div>
          <div className="box d-flex">
            <div className="mx-auto d-flex flex-wrap align-items-center">
              {skillsList.map((i, index) => (
                <button
                  onDragStart={(e) => {
                    DragStart(e);
                  }}
                  onMouseEnter={()=> {setShowDelete(index)}}
                  onMouseLeave={()=> {setShowDelete()}}

                  draggable="true"
                  type="button"
                  className="button"
                  value={i}
                >
                  {i}
                <>   <AiFillCloseCircle className="icon ms-2 cursor-pointer" onClick={()=>{setShowPopup(index)}} />  </>
                </button>
              ))}
              <BsPlusCircle type="button" className="fs-3" onClick={()=>{setAddShowPopup(true)}} />

            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label class="form-label">Operators</label>
          <div className="box d-flex">
            <div className="mx-auto d-flex">
              <button className="button" onClick={addColumn} type="button">
                AND
              </button>
              <button className="button" onClick={addRow} type="button">
                OR
              </button>
            </div>
          </div>
        </div>
      </div>
     
      <div className="row mt-4">
        <div className="col">
          <div className="box">
            <div className="d-flex flex-wrap p-4">
              <div className="w-100">
                 <div className="toasts-container position-absolute  mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-3">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              <p className="text-success">"Copied Successfully!"</p>
              
            </div>
          </div>
        </div>
                {row.map((i, j) => {
                  return (
                    <div className="d-flex justify-content-center" key={j}>
                      {column.map((n, k) => {
                        return (
                          <input
                            className="form-control w-25"
                            onDrop={(e) => Drop(e, n, i)}
                            onChange={(e) => Change(e, n, i)}
                            type="text"
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              
            </div>
          </div>
        </div>
        
      </div>
   
      <hr className="mx-auto" />

      <div className="row d-flex">
     
       <div className="col-xl-11">
       <textarea
          onChange={handleInputChange}
          className="text-center form-control w-75 mx-auto w-100"
          type="text"
          rows="2"
          value={final}
        >
          </textarea>
       </div>
       <div className="col-xl-1">
       <CopyToClipboard text={final}>
              <button type="button">
                <FiIcons.FiCopy
                  onClick={handleCopy}
                  className="recruit-icons"
                />
              </button>
            </CopyToClipboard>
       </div>
       <div className="col-xl-11 text-end  d-block me-5">
       {skillCount?" No of characters: "+final.length:""}

        
       </div>
      </div>
    
      
    </div>
  );
};

export default Option1;
