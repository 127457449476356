import TokenService from "./token.service";
import axios from "../config/axios";

class ScheduledUsersService {
  createSchedule = async (userData) => {
    const response = await axios.post(`/schedule`, userData);
    return response.data;
  };

  // getUsers = async (pageNo = 1) => {
  //   const response = await axios.get(`/users?page=${pageNo}&limit=5000`, {
  //     headers: {
  //       Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
  //     },
  //   });
  //   return response.data;
  // };

  // Purpose: Retrieve all users flagged for schedule deletion in the manage user section to display a calendar icon in red.
  getSchedules = async () => {
    const url = `/schedule?limit=5000`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };

  getSchedule = async (userId) => {
    const url = `/schedule?&userId=${userId}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };

  deleteSchedule = async (userId) => {
    const response = await axios.delete(`/schedule/${userId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };
}

export default new ScheduledUsersService();
