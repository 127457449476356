import React, { useEffect, useState } from "react";
import SingleUser from "./SingleUser";
import MultiUser from "./MultiUser";
import ManageUser from "./ManageUser";

function UserRegistrationContent() {
  const [location, setLocation] = useState("single-user");
  useEffect(() => {
    let path = window.location.pathname;
    if (path.includes("bulk-user")) setLocation("bulk-user");
    else if (path.includes("manage-user")) setLocation("manage-user");
    else if (path.includes("single-user/update"))
      setLocation("single-user/update");
    else setLocation("single-user");
  }, [window.location.pathname]);

  return (
    <div className="d-flex justify-content-space-between user-container">
      {location === "single-user" ? <SingleUser /> : null}
      {location === "single-user/update" ? <SingleUser /> : null}
      {location === "bulk-user" ? <MultiUser /> : null}
      {location === "manage-user" ? <ManageUser /> : null}
    </div>
  );
}

export default UserRegistrationContent;
