import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../features/userSlice";
import * as MdIcons from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import CommonService from "../../services/common.service";
// import MinioService from "../../libs/MinioService";
import minioService from "../../services/minio.service";
import Notification from "../Notification/Notification";
import { Toast } from "bootstrap";
import { IoMdNotifications, IoMdAdd } from "react-icons/io";
import { AiFillEdit, AiOutlineClose } from "react-icons/ai";
import dashboardService from "../../services/dashboard.service";
import { BiSolidTrash } from "react-icons/bi";
import licenceValidator from "../../permission/validate";

function Header() {
  const { user } = useSelector((state) => state.user);
  const [notifications, setNotifications] = useState([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const {
    toggleShowSidebar,
    showMemoryImage,
    showMemoryUploadForm,
    showMemoryAlbumForm,
    showProfileDropdown,
    showFeedbackForm,
    toggleFeedbackForm,
    toggleProfileDropdown,
    toggleProfileSidebar,
    toggleShowNotification,
    showNotification,
  } = useDOMStatesContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const [showNotificationForm, setShowNotificationForm] = useState(false);
  const [date, setDate] = useState(new Date().toLocaleDateString("en-CA"));
  const [isvisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);

  // notification list to be shown on deletion/edit form.
  const [notificationDeleteList, setNotificationDeleteList] = useState([]);
  const [notificationDeleteDate, setNotificationDeleteDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [showEditNotificationForm, setShowEditNotificationForm] =
    useState(false);
  const feedbackImageRef = useRef(null);

  const togglePopup = () => {
    toggleFeedbackForm();
    setSuccess("");
    setError("");
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
    setError("");
    setSuccess("");

    if (e.target.value.length >= 500) {
      const toast1 = document.getElementById("toast-1");
      const toast = new Toast(toast1);
      toast.show();
      setError("You have reached to character limit of 500.");
    }
  };
  function isImageFile(file) {
    // Check file extension
    const allowedImageExtensions = ['jpg', 'jpeg', 'png'];
    const allowedImageMimeTypes = ['image/jpeg', 'image/png'];
    const extension = file.name.split('.').pop().toLowerCase();
    if (allowedImageExtensions.includes(extension)) {
      return true;
    }
  
    // Check MIME type (if available)
    if (file.mimetype && allowedImageMimeTypes.includes(file.mimetype)) {
      return true;
    }
  
    return false;
  }
  const handleSendFeedback = async (e) => {
    e.preventDefault();
if(feedbackImageRef.current.files[0]){
  if(!isImageFile(feedbackImageRef.current.files[0])){
    setSuccessNotific("");
    setErrorNotific("Feedback image must be in required format(jpg, jpeg, png)");
    setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, [4000]);
      return
  }
}
    if (feedback.length < 10) {
      setSuccess("");
      const toast1 = document.getElementById("toast-1");
      const toast = new Toast(toast1);
      toast.show();
      setError("Please enter atleast 10 characters");
    } else {
      try {
        const formData = new FormData();
        formData.append("email", user.email);
        formData.append("feedback", feedback);
        const res = await minioService.UploadSingleFile(
          feedbackImageRef.current.files[0],
          "feedbackImage"
        );
        const url =
          `https://bucket.akinolabs.com:443/${process.env.REACT_APP_BUCKET_NAME}/feedbackImage/` +
          feedbackImageRef.current.files[0].name
            .replace(/(\(|\))/gi, "_")
            .replace(/ /g, "_");
        formData.append("feedbackImage", url);
        const result = await CommonService.sendFeedback(formData);
        setError("");
        const toast1 = document.getElementById("toast-1");
        const toast = new Toast(toast1);
        toast.show();
        setFeedback(""); // Clear the text area
        togglePopup();
        setSuccess("Thank You for your valuable feedback!");
      } catch (err) {
        setSuccess("");
        setError("Server Error");
      }
    }
  };

  const handleClosePopup = () => {
    setFeedback(""); // Clear the text area
    togglePopup();
    setSuccess("");
  };

  const notificationTitleRef = useRef("");
  const notificationLinkRef = useRef("");
  const [notificationStartDate, setNotificationStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [notificationEndDate, setNotificationEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const handleNotificationStartDateChange = (value) => {
    let tempDate = new Date().toLocaleDateString("en-CA");
    if (value < tempDate) {
      setStartDateError("Start date cannot be a past date");
    } else if (value > notificationEndDate) {
      setStartDateError("Start date cannot be after end date");
    } else {
      setStartDateError("");
    }
    setNotificationStartDate(value);
  };

  const handleNotificationEndDateChange = (value) => {
    let tempDate = new Date().toLocaleDateString("en-CA");
    if (notificationStartDate > value) {
      setEndDateError("End date cannot be before start date");
    } else if (value < tempDate) {
      setEndDateError("End date cannot be a past date");
    } else {
      setEndDateError("");
    }
    setNotificationEndDate(value);
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "home");
    setIsVisible(validator.isReadable("notifications"));
  }, []);

  const handleNotificationUpload = async (e) => {
    e.preventDefault();
    if (startDateError.length === 0 && endDateError.length === 0) {
      try {
        const result = await dashboardService.createNotification({
          notificationTitle: notificationTitleRef.current.value,
          link: notificationLinkRef.current.value,
          startDate: notificationStartDate,
          endDate: notificationEndDate,
        });
        setErrorNotific("");
        setSuccessNotific("Notification created");
      } catch (error) {
        setErrorNotific("Notification creation failed");
        setSuccessNotific("");
        console.log(error);
      }
      // getNotifications();
      setShowNotificationForm(false);
      setShowAlert(true);
      // setTimeout(() => {
      //   setShowAlert(false);
      // }, 3000);
    }
  };

  const getNotifications = async () => {
    try {
      const result = await dashboardService.getNotifications(date);
      setNotifications(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getNotificationForDate = async (date) => {
    try {
      const result = await dashboardService.getNotifications(date);
      setNotificationDeleteList(result);
      // console.log(result);
    } catch (error) {
      console.log(error);
    }
    setShowEditNotificationForm(true);
  };

  const deleteNotification = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this notification?"
    );
    if (confirmed) {
      try {
        const response = await dashboardService.deleteNotification({ id: id });
      } catch (error) {
        console.log(error);
      }
    }

    getNotificationForDate(notificationDeleteDate);
    // getNotifications();
  };

  useEffect(() => {
    // getNotifications();
  }, []);

  return (
    <div
      id="header"
      className="app-header"
      style={
        isOpen ||
        showMemoryAlbumForm ||
        showMemoryImage ||
        showMemoryUploadForm ||
        showProfileDropdown ||
        showNotification ||
        showNotificationForm ||
        showEditNotificationForm || 
        showFeedbackForm
          ? { zIndex: "50" }
          : {}
      }
    >
      {showMemoryAlbumForm || showMemoryImage || showMemoryUploadForm ? (
        <div className="gradient-tint"></div>
      ) : null}

      <div className="header-content d-flex justify-content-between">
        <GiHamburgerMenu
          className="hamburger-icon"
          onClick={toggleShowSidebar}
        />

        <div className="augmentry"></div>

        <div className="menu">
          <button
            className="feedback-btn text-white p-1 "
            onClick={togglePopup}
          >
            Feedback &nbsp;
            <MdIcons.MdOutlineFeedback className="fs-4 feed-icon" />
          </button>

          {/* <IoMdNotifications
            style={{ padding: "5px", width: "30px", height: "30px" }}
            role="button"
            onClick={() => {
              toggleShowNotification();
            }}
          /> */}

          {showAlert ? (
            <div className="alert-container">
              <div className="upper-section">
                <p>ALOIS</p>
                <span
                  className="delete-btn"
                  onClick={() => setShowAlert(!showAlert)}
                >
                  <AiOutlineClose className="icon" />
                </span>
              </div>
              <hr />
              <div className="lower-section pt-2">
                <p className="text-danger">{errorNotific}</p>
                <p className="text-success">{successNotific}</p>
              </div>
            </div>
          ) : null}

          {/* {showNotification ? (
            <div className="notifications-container">
              <div className="header text-center fw-bold d-flex justify-content-between">
                <div>Notification</div>
                {isvisible ? (
                  <div className="d-flex">
                    <div
                      className="icon px-1"
                      role="button"
                      onClick={() => {
                        setShowNotificationForm(true);
                        toggleShowNotification();
                      }}
                    >
                      <IoMdAdd style={{ width: "22px", height: "22px" }} />
                    </div>
                    <div
                      className="icon px-1"
                      role="button"
                      onClick={() => {
                        getNotificationForDate(date);
                        toggleShowNotification();
                      }}
                    >
                      <AiFillEdit />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="body">
                <ul>
                  {notifications &&
                    notifications.map((item, index) => {
                      if (item.status) {
                        if (item.link.length > 2)
                          return (
                            <NavLink
                              to={item.link}
                              target="_blank"
                              className="d-flex notification-link"
                              key={index}
                            >
                              <div>🔹</div>
                              <li> {item.notificationTitle} </li>
                            </NavLink>
                          );
                        else
                          return (
                            <div className="d-flex" key={index}>
                              <div>🔹</div>
                              <li> {item.notificationTitle} </li>
                            </div>
                          );
                      } else return null;
                    })}
                </ul>
              </div>
            </div>
          ) : null} */}

          {/* Notification form */}
          {showNotificationForm ? (
            <div className="notification-popup-form">
              <div className="popup">
                <div className="popup-header">
                  <span className="fs-6 fw-bold">Create Notification</span>
                  <button
                    className="close-button"
                    onClick={() => {
                      setShowNotificationForm(false);
                      setEndDateError("");
                      setStartDateError("");
                      setNotificationStartDate(
                        new Date().toLocaleDateString("en-CA")
                      );
                      setNotificationEndDate(
                        new Date().toLocaleDateString("en-CA")
                      );
                    }}
                  >
                    &#10005;
                  </button>
                </div>
                <hr style={{ borderTop: "3px dotted black" }} />
                <div className="popup-content">
                  <form
                    onSubmit={handleNotificationUpload}
                    encType="multipart/form-data"
                  >
                    <div className="form-fields-container">
                      <div className="row mt-3 pb-3">
                        <div className="col">
                          <div className="form-outline">
                            <label
                              className="form-label"
                              htmlFor="notice-title"
                            >
                              Notification Title*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="notice-title"
                              placeholder="notification title"
                              ref={notificationTitleRef}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 pb-3">
                        <div className="col">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="link">
                              Link
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="link"
                              placeholder="link"
                              ref={notificationLinkRef}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 pb-3">
                        <div className="col">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="date">
                              Start date*
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="date"
                              id="date"
                              value={notificationStartDate}
                              onChange={(e) =>
                                handleNotificationStartDateChange(
                                  e.target.value
                                )
                              }
                            />
                            <span className="text-danger h6">
                              {startDateError}
                            </span>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline">
                            <label className="form-label" htmlFor="date">
                              End date*
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="date"
                              id="date"
                              value={notificationEndDate}
                              onChange={(e) =>
                                handleNotificationEndDateChange(e.target.value)
                              }
                            />
                            <span className="text-danger h6">
                              {endDateError}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upload-btn-container mt-2">
                      <button className="generate-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}

          {/* notification edit form */}
          {showEditNotificationForm ? (
            <div className="notification-popup-form">
              <div className="popup">
                <div className="popup-header">
                  <span className="fs-6 fw-bold">Delete Notification</span>
                  <button
                    className="close-button"
                    onClick={() =>
                      setShowEditNotificationForm(!showEditNotificationForm)
                    }
                  >
                    &#10005;
                  </button>
                </div>
                <hr style={{ borderTop: "3px dotted black" }} />
                <div className="input-date d-flex justify-content-end">
                  <input
                    type="date"
                    style={{ border: "1px solid #175572", borderRadius: "7px" }}
                    value={notificationDeleteDate}
                    onChange={(e) => {
                      setNotificationDeleteDate(e.target.value);
                      getNotificationForDate(e.target.value);
                    }}
                  />
                </div>
                <div className="popup-content">
                  <div className="d-flex flex-column">
                    {notificationDeleteList.map((item, index) => {
                      if (item.status) {
                        return (
                          <div className="d-flex justify-content-between py-2">
                            <div class="notification-title px-1">
                              {" "}
                              🔹{item.notificationTitle}
                            </div>
                            <div
                              className="delete-btn px-2"
                              role="button"
                              onClick={() => deleteNotification(item.id)}
                            >
                              <BiSolidTrash style={{ fill: "red" }} />
                            </div>
                          </div>
                        );
                      } else return null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showFeedbackForm && (
            <div className="popup-container">
              <div className="popup">
                <div className="popup-header">
                  <span className="fs-6 fw-bold" style={{ color: "#175572" }}>
                    Your Feedback Matters
                  </span>

                  <button className="close-button" onClick={handleClosePopup}>
                    &#10005;
                  </button>
                </div>
                <hr />

                <div className="popup-content">
                  <form>
                    <div className="form-outline my-2">
                      {/* <label
                        className="form-label fw-bold"
                        style={{ color: "#175572" }}
                        htmlFor="chooseImage"
                      >
                        Choose Image
                      </label> */}
                      <input
                        type="file"
                        className="form-control"
                        accept=".png,.jpeg,.jpg"
                        style={{ border: "1px solid #daeff4" }}
                        ref={feedbackImageRef}
                      />
                    </div>

                    <textarea
                      className="feedback-input"
                      placeholder=""
                      value={feedback}
                      onChange={handleFeedbackChange}
                      maxLength={500}
                      required
                    ></textarea>

                    <center>
                      <button
                        className="generate-btn"
                        onClick={handleSendFeedback}
                      >
                        Send
                      </button>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          )}

          <Notification success={success} error={error} />

          <div className="menu-item dropdown">
            <a
              href="#/"
              onClick={() => {
                setShowDropdown(!showDropdown);
                toggleProfileSidebar();
              }}
              data-bs-toggle="dropdown"
              data-bs-display="static"
              className="menu-link"
            >
              <div className="menu-text fw-bold px-3">
                {user.firstName} {user.lastName}
              </div>

              <div className="menu-img online">
                <img src="/assets/profile.jpg" alt="Profile" height="50" />
              </div>
            </a>

            <div
              id="dropdown"
              className={
                showDropdown
                  ? "dropdown-menu dropdown-menu-end"
                  : "dropdown-menu dropdown-menu-end"
              }
              style={{ display: "none !important" }}
            >
              <Link
                to="/profile"
                className="dropdown-item d-flex align-items-center"
              >
                PROFILE
              </Link>

              <div className="dropdown-divider"></div>

              <Link
                to="/"
                onClick={() => {
                  dispatch(logout());
                }}
                className="dropdown-item d-flex align-items-center"
              >
                LOGOUT
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
