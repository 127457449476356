// import React, { useEffect, useState } from "react";
// // import SingleUser from "./SingleUser";
// import SingleUser from "../UserRegistration/SingleUser";
// import AccessManagementByRoles from "./AccessManagementByRoles";


// function AccessManagementContent() {
  
//   const [location, setLocation] = useState("access-control");
//   useEffect(() => {

//     let path = window.location.pathname;
//     if (path.includes("access-control")) setLocation("access-control");
//   }, [window.location.pathname]);

//   return (
//     <div className="d-flex justify-content-space-between user-container">
//       {location === "access-control" ? <AccessManagementByRoles /> : null}
    
//     </div>
//   );
// }

// export default AccessManagementContent;
