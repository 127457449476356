import TokenService from "./token.service";
import axios from "../config/axios";
import alt_axios from "axios";

class QuizResultService {
  createQuizResult = async (data) => {
    const response = await axios.post(`/quizResult/create`, data);
    console.log("Service", response);
    return response.data;
  };
  updateQuizResult = async (id,data) => {
    const response = await axios.post(`/quizResult/${id}`, data);
    console.log("Service", response);
    return response.data;
  };
  getFullNameByDomain = async (domain) => {
    
    const response = await axios.get(`/quizResult/fullName?domain=${domain}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
//   getQuizResults = async (userId, domain) => {
//     if (userId && domain) {
//     const response = await axios.get(`/quizResult/`, {
//       headers: {
//         Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
//       },
//     });
//     return response.data;
//   }
// }
// }
getQuizResults = async (userId, domain) => {
  const headers = {
    Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
  };

  // Construct the request URL based on userId and domain
  let url = '/quizResult?';
  if (userId && domain) {
    url += `&userId=${userId}&domain=${domain}`;
  } else if (userId) {
    url += `&userId=${userId}`;
  } else if (domain) {
    url += `&domain=${domain}`;
  }
  url +="&limit=5000"
  

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching quiz results:', error);
    throw error;
  }
};
}

export default new QuizResultService();
