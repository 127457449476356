import React from "react";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import ClanIcon from "../assets/clan-icon.svg";
import UM from "../assets/UM.svg";
import Dot from "../assets/dot.svg";
import SM from "../assets/SM.svg";
import EM from "../assets/EM.svg";
import AM from "../assets/AM.svg";
import { FaBirthdayCake } from "react-icons/fa";

let icon = ClanIcon;
let icon1 = UM;
let icon2 = Dot;
let icon3 = SM;
let icon4 = EM;
let icon5 = AM;

const Menu = [
  { is_header: true, title: "Navigation" },
  {
    path: "/dashboard",
    icon: <AiIcons.AiFillHome />,
    title: "Home",
    type: "Public",
  },
  {
    path: "/assist",
    icon: <MdIcons.MdMiscellaneousServices />,
    title: "Assist",
    titleSlug: "assist",
    type: "Public",
  },
  {
    path: "/memories",
    icon: <MdIcons.MdPhotoLibrary />,
    title: "Memories",
    titleSlug: "memories",
    type: "Public",
  },
  {
    path: "/buzz",
    icon: <MdIcons.MdSnippetFolder />,
    title: "Buzz",
    type: "Public",
    titleSlug: "buzz",
  },
  {
    path: "/admin",
    icon: (
      <img
        src={icon1}
        style={{ color: "#678498", opacity: "70%", height: "13px" }}
      />
    ),
    title: "Admin",
    titleSlug: "admin",
    type: "Partial",
    children: [
      {
        path: "/user-registration",
        icon: (
          <img
            src={icon1}
            style={{ color: "#678498", opacity: "70%", height: "13px" }}
          />
        ),
        title: "User Registration",
        titleSlug: "user_registration",
        type: "Partial",
        children: [
          {
            path: "/single-user",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Single User",
            type: "Private",
            titleSlug: "single_user",
          },
          {
            path: "/bulk-user",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Multi Users",
            titleSlug: "multi_users",
            type: "Private",
          },
          {
            path: "/manage-user",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Manage User",
            type: "Private",
            titleSlug: "manage_user",
          },
        ],
      },
      {
        path: "/feedbacks",
        icon: (
          <img
            src={icon2}
            style={{ color: "#678498", opacity: "70%", height: "13px" }}
          />
        ),
        title: "Feedback",
        titleSlug: "feedback",
        type: "Private",
      },
      {
        path: "/template-bank",
        icon: (
          <img
            src={icon3}
            style={{ color: "#678498", opacity: "70%", height: "13px" }}
          />
        ),
        title: "Template Bank",
        titleSlug: "template_bank",

        type: "Partial",
        children: [
          {
            path: "/social-media-templates",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Social Media ",
            titleSlug: "social_media_templates",
          },
          {
            path: "/birthday-templates",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Birthday ",
            titleSlug: "birthday_template_bank",
          },
          {
            path: "/anniversary-templates",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Anniversary ",
            titleSlug: "anniversary_template_bank",
          },
        ],
      },
      {
        path: "/clan-score-master",
        icon: <img src={icon} height="17px" />,
        title: "Clan Score",
        titleSlug: "clan_score_master",
        type: "Private",
      },
      {
        path: "/birthday-post",
        icon: <FaBirthdayCake />,
        title: "Birthday Post",
        titleSlug: "birthday_post",
        type: "Private",
      },
      {
        path: "/anniversary-post",
        icon: <FaBirthdayCake />,
        title: "Anniversary Post",
        titleSlug: "birthday_post",
        type: "Private",
      },
      // {
      //   path: "/jd-upload",
      //   icon: <MdIcons.MdUploadFile />,
      //   title: "JD Upload",
      //   titleSlug: "jd_upload",
      //   type: "Private",
      // },
      // {
      //   path: "/quiz-boolean",
      //   icon: <MdIcons.MdOutlineQuiz />,
      //   title: "Quiz",
      //   titleSlug: "boolean_quiz",
      //   type: "Private",
      // },
      // {
      //   path: "/quiz-evaluation",
      //   icon: <MdIcons.MdUploadFile />,
      //   title: "Quiz Evaluate",
      //   titleSlug: "boolean_query_generator",
      //   type: "Public",
      // },
      // {
      //   path: "/quiz-result",
      //   icon: <MdIcons.MdUploadFile style={{ height: "16px" }} />,
      //   title: "Quiz Result",
      //   titleSlug: "quiz_result",
      //   type: "Private",
      // },
      {
        path: "/mail",
        icon: (
          <img
            src={icon4}
            style={{ color: "#678498", opacity: "70%", height: "16px" }}
          />
        ),
        title: "Emailers",
        titleSlug: "emailers",
        type: "Partial",
        children: [
          {
            path: "/bulk-mail",
            type: "Private",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Bulk Emails",
            titleSlug: "bulk_mail",
          },
          {
            path: "/previous-mail",
            type: "Private",
            icon: (
              <img
                src={icon2}
                style={{ color: "#678498", opacity: "70%", height: "13px" }}
              />
            ),
            title: "Previous Emails ",
            titleSlug: "previous_mail",
          },
        ],
      },

      {
        path: "/access-control",
        icon: (
          <img
            src={icon5}
            style={{ color: "#678498", opacity: "70%", height: "17px" }}
          />
        ),
        title: "Access Management",
        titleSlug: "role_based_access_control",
        type: "Private",
      },
      {
        path: "/activity-log",
        icon: <MdIcons.MdPhotoLibrary />,
        title: "Activity Log",
        titleSlug: "activity_log",
        type: "Private",
      },
    ],
  },
];

export default Menu;
