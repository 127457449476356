import React from "react";
import ClanGraph from "../../components/ClanGraph/ClanGraph";
import ImageCarousel from "../../components/Widgets/ImageCarousel";
import NoticeBoard from "../../components/NoticeBoard/NoticeBoard";

function Dashboard() {
  const userData = JSON.parse(localStorage.user);
  const name = `${userData.firstName} ${userData.lastName}`;

  return (
    <div className="dashboard-container px-1" style={{ marginTop: "0.6rem" }}>
      {/* <span className="greet py-2">
        <em>
          Welcome <b>{name}</b>
        </em>
      </span> */}

      <div className="d-flex gap-2 dashboard-upper-section">
        <div className="dashboard-images-container">
          <ImageCarousel />
        </div>

        <NoticeBoard />
      </div>

      <div className="d-flex mid-section mb-5">
        <ClanGraph />
      </div>
    </div>
  );
}
export default Dashboard;
