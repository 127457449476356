import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import permissionReducer from "../features/permissionSlice";
import buzzReducer from "../features/buzzSlice";
import photoReducer from "../features/photoSharingSlice";
import skillsReducer from '../features/skillSlice'
import dashboardReducer from "../features/dashboardSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    permission: permissionReducer,
    buzz: buzzReducer,
    skills:skillsReducer,
    photoSharing: photoReducer,
    dashboard: dashboardReducer,
  },
});
