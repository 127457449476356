import TokenService from "./token.service";
import axios from "../config/axios";

class SocialTemplateBankService {
  getTemplates = async (pageNo, department) => {
    let url = `/linkedin/showSMTemplates?limit=10`
    if(department){
      url = `${url}&department=${department}`
    }
    if(pageNo){
     url = `${url}&page=${pageNo}`
    }
    const res = await axios.get(
     url,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return res;
  };
}

export default new SocialTemplateBankService();
