import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { CgProfile, CgLogOut } from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import { logout } from "../../features/userSlice";

function ProfileSidebar() {
  const dispatch = useDispatch();

  const { toggleProfileSidebar, toggleFeedbackForm } = useDOMStatesContext();

  const togglePopup = () => {
    toggleProfileSidebar();
    toggleFeedbackForm();
  };

  const handleLogout = () => {
    toggleProfileSidebar();
    dispatch(logout());
  };

  return (
    <div className="profile-sidebar-container">
      <div className="upper-section">
        <AiFillCloseCircle
          className="close-btn"
          onClick={toggleProfileSidebar}
        />

        <div className="profile-img" style={{ paddingTop: "20px" }}>
          <p
            style={{
              paddingTop: "10px",
              paddingRight: "5px",
              fontFamily: "Inter",
            }}
          >
            {JSON.parse(localStorage.user).firstName +
              " " +
              JSON.parse(localStorage.user).lastName}
          </p>

          <img src="/assets/profile.jpg" alt="Profile" height="50" />
        </div>
      </div>

      <div className="content pt-3 me-2">
        <ul>
          {/* <NavLink to={"/profile"} className="my-2">
            Profile <CgProfile className="icon" />
          </NavLink> */}

          <NavLink to={"/"} onClick={handleLogout}>
            Logout
            <CgLogOut className="icon" />
          </NavLink>

          <NavLink
            className="d-flex align-items-center"
            onClick={() => {
              togglePopup();
            }}
          >
            Feedback
            <MdIcons.MdOutlineFeedback className="fs-4 feed-icon" />
          </NavLink>
        </ul>
      </div>
    </div>
  );
}

export default ProfileSidebar;
