import React, { useRef, useState } from "react";
import * as FiIcons from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { AiOutlineClose } from "react-icons/ai";
import { RxReset } from "react-icons/rx";
import logo from './ALOIS_SOLUTIONS_LOGO.png'


const GenerateBox = ({ text, showJdIcons, showSocialIcons, fileName, handleSubmit, handleReset }) => {
  const [answer, setAnswer] = useState(text);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState("")
  const refDiv = useRef();
  const handleCopy = async (e) => {
    setSuccess("Copied Successfully!");
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2000);
  };
  const DownloadPDF = () => {};
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    text: { 
      fontSize: 12,
    },
    title: {
      fontSize: 14,
      textAlign: "center",
    },
    image:{
      height: 50,
      width: 100,
      textAlign: "center",
    }
  });

  // const styles = StyleSheet.create({
  //   body: {
  //     paddingTop: 35,
  //     paddingBottom: 65,
  //     paddingHorizontal: 35,
  //   },
  //   title: {
  //     fontSize: 24,
  //     textAlign: 'center',
  //     fontFamily: 'Oswald'
  //   },
  //   author: {
  //     fontSize: 12,
  //     textAlign: 'center',
  //     marginBottom: 40,
  //   },
  //   subtitle: {
  //     fontSize: 18,
  //     margin: 12,
  //     fontFamily: 'Oswald'
  //   },
  //   text: {
  //     margin: 12,
  //     fontSize: 14,
  //     textAlign: 'justify',
  //     fontFamily: 'Times-Roman'
  //   },
  //   image: {
  //     marginVertical: 15,
  //     marginHorizontal: 100,
  //   },
  //   header: {
  //     fontSize: 12,
  //     marginBottom: 20,
  //     textAlign: 'center',
  //     color: 'grey',
  //   },
  //   pageNumber: {
  //     position: 'absolute',
  //     fontSize: 12,
  //     bottom: 30,
  //     left: 0,
  //     right: 0,
  //     textAlign: 'center',
  //     color: 'grey',
  //   },
  // });



  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
        <Image
        style={styles.image}
        src={logo}
        fixed
      />          
          <Text style={styles.text}>{answer}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="generate-box " style={{ position: "relative" }}>
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{error}</p>
            <p className="text-success">{success}</p>
          </div>
        </div>
      ) : null}
        <textarea
          ref={refDiv}
          className="form-control"
          onChange={(e) => setAnswer(e.target.value)}
          rows="15"
          cols="150"
        >
          {text}
        </textarea>
        {showJdIcons && (
          <div className="icon-holder d-flex justify-content-between">
            <div>
      
              <button type="button">
                <FiIcons.FiRefreshCcw
                  onClick={handleSubmit}
                  className="recruit-icons"
                />
              </button>
           

           
              <button type="button">
                <RxReset
                  onClick={handleReset}
                  className="recruit-icons"
                />
              </button>
       
            </div>
            {/* <FaIcons.FaRegEdit onClick={() => console.log(textRef.current.value)} className="recruit-icons"/> */}
         <div>
         <CopyToClipboard text={answer}>
              <button type="button">
                <FiIcons.FiCopy
                  onClick={handleCopy}
                  className="recruit-icons"
                />
              </button>
            </CopyToClipboard>

            <PDFDownloadLink
              className="bg-white"
              document={<MyDocument />}
              fileName={fileName}
              style={{
                textDecoration: "none",
                padding: "10px",
                color: "#4a4a4a",
                backgroundColor: "#f2f2f2",
                border: "1px solid #4a4a4a",
              }}
            >
              <FiIcons.FiDownload
                onClick={DownloadPDF}
                className="recruit-icons"
              />
            </PDFDownloadLink>
         </div>
            {/* <Notification success={success} error={error}/>  */}

            {/* <FiIcons.FiShare2 className="recruit-icons"/>  */}
          </div>
        )}

        {showSocialIcons && (
            <div className="icon-holder d-flex justify-content-between">
                    <div>
      
              <button type="button">
                <FiIcons.FiRefreshCcw
                  onClick={handleSubmit}
                  className="recruit-icons"
                />
              </button>
           

           
              <button type="button">
                <RxReset
                  onClick={handleReset}
                  className="recruit-icons"
                />
              </button>
       
            </div>
            <CopyToClipboard text={answer}>
              <button type="button">
                <FiIcons.FiCopy
                  onClick={handleCopy}
                  className="recruit-icons"
                />
              </button>
            </CopyToClipboard>
            {/* <FiIcons.FiShare2 className="recruit-icons" /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateBox;
