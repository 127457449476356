import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Option1 from '../Option1/Option1';
import Option2 from '../Option2/Option2';
import OptionParent from '../OptionParent/Optionparent';
const QueryGenerateBox = (skill) => {

  useEffect(() => {
    // JavaScript initialization code
    var tabTriggerEl = document.getElementById('tab-login');
    var tab = new bootstrap.Tab(tabTriggerEl);
    tab.show();
  }, []);

  return (
    <div className="query-generate-box mt-5 mx-auto w-100">
        <ul className="nav nav-pills nav-justified" id="ex1" role="tablist">
            <li className="nav-item" role="presentation">
                <a
                    className="nav-link active" // Added the 'active' class
                    id="tab-login"
                    data-bs-toggle="pill"
                    href="#pills-login"
                    role="tab"
                    aria-controls="pills-login"
                    aria-selected="true"
                >
                    Option 1
                </a>
            </li>
            <li className="nav-item" role="presentation">
                <a
                    className="nav-link"
                    id="tab-register"
                    data-bs-toggle="pill"
                    href="#pills-register"
                    role="tab"
                    aria-controls="pills-register"
                    aria-selected="false"
                >
                    Option 2
                </a>
            </li>
        </ul>
        <OptionParent skill={skill.skill}/>

        {/* <div className="tab-content">
            <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
            </div>
            <div className="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
            <OptionParent skill={skill.skill}/>
            </div>

        </div> */}
    </div>
  );
};

export default QueryGenerateBox;
