import React from "react";

function Button(props) {
  const { text, bgColor, icon } = props;

  return (
    <button className="custom-btn" style={{ background: bgColor }}>
      <pre className="pretag">{text}</pre>
    </button>
  );
}

export default Button;
