import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";

function Popup(props) {
  const {
    clickHandler,
    submitHandler,
    header,
    label,
    btnText,
    inputType,
    setImageFile,
    albumName,
    setAlbumName,
    inputRef,
  } = props;

  return (
    <form
      className="memory-upload-container"
      onSubmit={submitHandler}
      encType={
        inputType === "file"
          ? "multipart/form-data"
          : "application/x-www-form-urlencoded"
      }
    >
      <div className="upper-section">
        <p className="header">{header}</p>
        {/* close button */}
        <span onClick={clickHandler}>
          <AiFillCloseCircle className="icon" />
        </span>
      </div>
      <hr className="mb-2" />
      {inputType === "file" ? (
        <React.Fragment>
          <div className="inner-div my-2">
            <label htmlFor="upload" className="form-label">
              {label}
            </label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              id="upload"
              
              
              onChange={(e) => setImageFile(e.target.files)}
              multiple
              required
            />
          </div>
        </React.Fragment>
      ) : (
        <div className="inner-div my-2">
          <label htmlFor="albumName" className="form-label">
            {label}
          </label>
          <input
            type="text"
            ref={inputRef}
            className="form-control"
            id="albumName"
            placeholder="Album Name"
            value={albumName}
            required
            onChange={(e) => setAlbumName((e.target.value))}
          />
        </div>
      )}

      <div
        style={{ display: "flex", justifyContent: "center", margin: "10px" }}
      >
        <button className="generate-btn" style={{ marginLeft: "0" }}>
          {btnText}
        </button>
      </div>
    </form>
  );
}

export default Popup;
