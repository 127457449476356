import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    notifications: [],
  },
  reducers: {
    setNotifications: (state, action) => {
      // console.log(action.payload);
      state.notifications = action.payload;
    },
  },
});

export const { setNotifications } = dashboardSlice.actions;
export default dashboardSlice.reducer;
