import React from 'react';
import { useLocation } from 'react-router-dom';

function Footer() {
	let location = useLocation()
	let isMailer = location.pathname.includes("/admin/mail")
	return (
		<div className='footer text-center' style={isMailer? {top:"125vh"}: {top:"100vh"}} >
			&copy; ALOIS 2023
		</div>
	);
};

export default Footer;