import React, { useEffect, useState } from "react";

function Notification({ success, error }) {
  return (
    <div className="toasts-container position-absolute top-0 mt-3 mx-3">
      <div className="toast fade hide" data-autohide="true" id="toast-1">
        <div className="toast-header">
          <i className="far fa-bell text-muted me-2"></i>
          <strong className="me-auto">ALOIS</strong>
          <button
            type="button"
            id="toastclose_btn"
            className="btn-close"
            data-bs-dismiss="toast"
          ></button>
        </div>
        <div className="toast-body">
          <p className="text-success">{success}</p>
          <p className="text-danger">{error}</p>
        </div>
      </div>
    </div>
  );
}

export default Notification;
