import TokenService from "./token.service";
import axios from "../config/axios";
import alt_axios from "axios";

class BooleanQueryService {
  createBooleanQuery = async (data) => {
    const response = await axios.post(`/booleanQueries/create`, data);
    console.log("Service", response);
    return response.data;
  };
  updateBooleanQuery = async (id, data) => {
    const response = await axios.post(`/booleanQueries/${id}`, data);
    console.log("Service", response);
    return response.data;
  };
  getBooleanQueries = async (id, pageNo =1) => {
    const response = await axios.get(`/booleanQueries/get?limit=1&page=${pageNo}&testId=${id}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  getAllBooleanQueries = async () => {
    const response = await axios.get(`/booleanQueries/get?limit=5000`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  getAnsweredBooleanQueries = async ( userId, questionId, pageNo = 1) => {

    const response = await axios.get(`/booleanQueries/get?limit=1&page=${pageNo}&userId=${userId}&questionId=${questionId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

}

export default new BooleanQueryService();
