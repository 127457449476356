import React, { useState, useEffect } from "react";
import EssNavbar from "../EssNavbar/EssNavbar";
import RecruiterSocial from "../RecruiterSocial/RecruiterSocial";
import SearchQueryGenerator from "../SearchQueryGenerator/SearchQueryGenerator";
import utilities from "../../pages/Ess/EssData";
import { Link, useLocation } from "react-router-dom";

import EssBackBtn from "../Widgets/EssBackBtn";

const EssDashboard = () => {
  const location = useLocation();
  const [activeCard, setActiveCard] = useState("card1");

  const handleCardClick = (cardName) => {
    setActiveCard(cardName);
  };

  useEffect(() => {
    const currentUtility = utilities.find(
      (utility) => utility.link === location.pathname
    );
    if (currentUtility) {
      const index = utilities.indexOf(currentUtility);
      setActiveCard(`card${index + 1}`);
    }
  }, [location.pathname]);

  const selectedUtility = utilities.find(
    (utility, index) => activeCard === `card${index + 1}`
  );

  const renderCardComponent = () => {
    if (selectedUtility) {
      const CardComponent = selectedUtility.component;
      return <CardComponent />;
    }
    return null;
  };

  return (
    <div>
      <EssNavbar activeCard={activeCard} handleCardClick={handleCardClick} />
      <div
        className="ess_outer_div mx-auto h-75 my-auto "
        style={{ paddingTop: "1px" }}
      >
        <div className="ess_inner_div mt-3">
          <div className="ess-dashboard">
            <div className="container d-flex">
              <EssBackBtn />
              <div className="row w-100">
                <div className="col">{renderCardComponent()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EssDashboard;
