import TokenService from "./token.service";
import axios from "../config/axios";

class MasterDBService {

  getMasterDB = async () => {
    let url = `/masterDB?limit=5000`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
}

export default new MasterDBService();