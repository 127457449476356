import React, { useRef, useState, useEffect } from "react";
import userRegistrationService from "../../services/userRegistration.service";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import licenceValidator from "../../permission/validate";
import { AiOutlineClose } from "react-icons/ai";
import masterDBService from "../../services/masterDB.service";

function SingleUser() {
  const empIdRef = useRef();
  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const departmentRef = useRef();
  const shiftRef = useRef();
  const usergroupRef = useRef();
  const shiftTimingsRef = useRef();
  const dobRef = useRef(); //Date of birth Ref
  const dojRef = useRef(); //Date of Joining Ref
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [formType, setFormType] = useState("register");
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [departments,setDepartments] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedShift,setSelectedShift] = useState('');
  const [shiftTimings, setShiftTimings] = useState([]);
  const [shiftTime,setShiftTime] = useState('');
  const [dateOfBirth,setDateOfBirth]= useState("");
  const [dateOfJoining,setDateOfJoining] = useState("");
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const navigate = useNavigate();


  const getMasterDB = async()=>{
    const data = await masterDBService.getMasterDB();
    setDepartments(data.results)
  }

  const handleDepartmentChange = () => {
    const selectedDepartmentName = departmentRef.current.value;
    setSelectedDepartment(selectedDepartmentName);
    shiftTimingsRef.current.value="default";
    shiftRef.current.value="default";
    setSelectedShift(''); 
  };

  const handleShiftChange = async () => {
    const selectedShiftName = shiftRef.current.value;
    setSelectedShift(selectedShiftName);
    const selectedDepartmentData = departments.find(department => department.name === selectedDepartment);
    const selectedShiftData = selectedDepartmentData?.shifts.find(shift => shift.name === selectedShiftName);
    setShiftTimings(selectedShiftData?.shiftTimings || []);
    setShiftTime('')
    shiftTimingsRef.current.value="default";
  };

  const handleShiftTimingChange = async () =>{
    const selectedShiftTime = shiftTimingsRef.current.value;
    setShiftTime(selectedShiftTime);
  }

  const handleDOBChange = async()=>{
    const selectedDate = dobRef.current.value;
    const [year, month, day] = selectedDate.split("-"); 
    const formattedDate = `${day}/${month}/${year}`;
    setDateOfBirth(formattedDate);
  }
  const handleDOJChange = async()=>{
    const selectedDate = dojRef.current.value;
    const [year, month, day] = selectedDate.split("-"); 
    const formattedDate = `${day}/${month}/${year}`;
    setDateOfJoining(formattedDate);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const handleUserRegistration = async () => {
    // console.log("register");
    try {
      if( /^[a-zA-Z\s]*$/g.test(firstNameRef.current.value) &&  /^[a-zA-Z\s]*$/g.test(lastNameRef.current.value) && /^[a-zA-Z0-9]+$/
.test(empIdRef.current.value)){

      const result = userRegistrationService.createSingleUser({
        empId: empIdRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        // name: firstNameRef.current.value,
        email: emailRef.current.value,
        department: departmentRef.current.value,
        // usergroup: usergroupRef.current.value,
        license: [
          {
            "_id": "651588f225e52f2fdc7ea58b",
            "moduleName": "assist",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "75683b4997c04b7891731c3401a07d79",
                "subModuleName": "jd_generator",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "380a82ac81e846acadf5e4c23953d9ce",
                "subModuleName": "recruiter_social",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "8b9f6ea51256437588c469cf587524c1",
                "subModuleName": "search_query_generator",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "bebdd9ba91144359ac40eab5ec1",
                "subModuleName": "recruiter_outreach",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "9700dec940b249b295911e3dd134e172",
                "subModuleName": "domain_identifier",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "90561bdec9f94e87a3eb28e21a56554c",
                "subModuleName": "recommended_jobs",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "5464b5460af448cbaced04a55e4a9545",
                "subModuleName": "relevant_candidate_finder",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "daaa953aa304466c84d6f642943d7016",
                "subModuleName": "skill_highlighter",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "daaa953aa304466c84d6f642943d8670",
                "subModuleName": "interview_q_and_a",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              }
            ]
          },
          {
            "_id": "649588f225e52f2fdc7ea58c",
            "moduleName": "memories",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "75683b4197c04b7891731c3401a07d79",
                "subModuleName": "photo_upload",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "_id": "649588f225e52fg4fdc7ea58b",
            "moduleName": "buzz",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "649288f235e52f2ddc7ea58c",
                "subModuleName": "buzz_admin",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "isAccessible": true,
            "moduleName": "home",
            "permissions": [
              {
                "_id": "75683b4c04b7891731c3401a07a99",
                "subModuleName": "notice_board",
                "create": false,
                "read": true,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4c04b7891731c3401a07b89",
                "create": false,
                "read": true,
                "update": false,
                "delete": false,
                "subModuleName": "bulletin"
              },
              {
                "create": false,
                "delete": false,
                "read": true,
                "subModuleName": "notifications",
                "update": false,
                "_id": "75683b4c04b7891731c3401a07c69"
              }
            ]
          },
          {
            "_id": "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
            "moduleName": "admin",
            "isAccessible": false,
            "permissions": [
              {
                "_id": "47697bb0-6127-4d88-835c-5bed120c9b91",
                "moduleName": "user_registration",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "649588f235e52f2fdc7ea58c",
                    "subModuleName": "single_user",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "649588f235e52f2fdc7ea58d",
                    "subModuleName": "multi_users",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "649588f235e52f2fma7ea58d",
                    "subModuleName": "manage_user",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {   
                "_id": "18533f25-94b2-4bbb-b966-32e7ee59a29c",
                "moduleName": "emailers",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                    "subModuleName": "bulk_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                    "subModuleName": "previous_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
               
                ]
              },
              {
                "_id": "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
                "moduleName": "template_bank",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "34d33fdc-435a-4150-821a-907e665cb6f7",
                    "subModuleName": "social_media_templates",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                    "subModuleName": "anniversary_template_bank",
                    "create": false,
                "read": false,
                "update": false,
                "delete": false
                  },
                  {
                    "_id": "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                    "subModuleName": "birthday_template_bank",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "75683b4197c04b7891731c3401a07d79",
                    "subModuleName": "image",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {
                "_id": "8a341493-7229-44b9-ab86-24a35a1a9c7d",
                "subModuleName": "jd_upload",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "247ca95b-9a17-4047-8729-66c846de9097",
                "subModuleName": "boolean_quiz",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "abed0c70-5214-4eea-a7c8-f301e7f70c98",
                "subModuleName": "quiz_result",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "d456826a-b317-423b-b568-9c8417fd5a9a",
                "subModuleName": "quiz_evaluation",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4c04b7891731c3401a07d79",
                "subModuleName": "role_based_access_control",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f225e52f1fdc7ea5we8b",
                "subModuleName": "clan_score_master",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
                "subModuleName": "birthday_post",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f225e52f1fdc7ea58b",
                "subModuleName": "activity_log",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },  
              {
                "_id": "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
                "subModuleName": "feedback",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          }
        ],
        role: "user",
        shiftTiming: shiftTimingsRef.current.value,
        shift: shiftRef.current.value,
        password: password,
        dateOfBirth:dateOfBirth,
        dateOfJoining:dateOfJoining
      });

      navigate("/admin/user-registration/manage-user");

    } else {
      if(!/^[a-zA-Z\s]*$/g.test(firstNameRef.current.value)){
        setShowAlert(true)
        setErrorNotific("First name must be alphabets only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
      if(!/^[a-zA-Z0-9]+$/
.test(empIdRef.current.value)){
        setShowAlert(true)
        setErrorNotific("Employee Id must be text and numbers only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
      if(!/^[a-zA-Z\s]*$/g.test(lastNameRef.current.value)){
        setShowAlert(true)
        setErrorNotific("Last name must be alphabets only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
    }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "user_registration");
    setIsVisible(validator.isReadable("single_user"));
    getMasterDB();
  }, []);
  const handleUserUpdate = async () => {
    try {
      
      // const userId = JSON.parse(sessionStorage.user).id;
      if( /^[a-zA-Z\s]*$/g.test(firstNameRef.current.value) &&  /^[a-zA-Z\s]*$/g.test(lastNameRef.current.value) && /^[a-zA-Z0-9]+$/
.test(empIdRef.current.value)){
      const userData1 = JSON.parse(sessionStorage.user);
      const userData = {
        empId: empIdRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        // name: firstNameRef.current.value,
        department: departmentRef.current.value,
        // usergroup: usergroupRef.current.value,
        // role: "user",
        shiftTiming: shiftTimingsRef.current.value,
        shift: shiftRef.current.value,
        password,
        email: emailRef.current.value,
        license: userData1.license,
        dateOfBirth:dateOfBirth,
        dateOfJoining:dateOfJoining
      };

      if (password === "") delete userData.password;

      let userId = userData1.id;
      const result = await userRegistrationService.updateUser(userId, userData);
      navigate("/admin/user-registration/manage-user");

    } else {
      if(!/^[a-zA-Z\s]*$/g.test(firstNameRef.current.value)){
        setShowAlert(true)
        setErrorNotific("First name must be alphabets only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
      if(!/^[a-zA-Z0-9]+$/
.test(empIdRef.current.value)){
        setShowAlert(true)
        setErrorNotific("Employee Id must be text and numbers only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
      if(!/^[a-zA-Z\s]*$/g.test(lastNameRef.current.value)){
        setShowAlert(true)
        setErrorNotific("Last name must be alphabets only")
        setTimeout(() => {
          setErrorNotific("")
          setShowAlert(false)
        }, 2000);
      }
    }
    } catch (error) {
      
      setShowAlert(true)
      setErrorNotific(error.response.data.message)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000);
      console.error(error);
    }
  };

  const autoGeneratePassword = () => {
    const length = 12;
    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialCharacters = "!@#$%^&*()";

    let randomPassword = "";
    for (let i = 0; i < length / 3; i++) {
      const randomSequence = Math.floor(Math.random() * 3);
      const randomNumber = Math.floor(Math.random() * numbers.length);
      const randomAlphabet = Math.floor(Math.random() * alphabets.length);
      const randomCharacters = Math.floor(
        Math.random() * specialCharacters.length
      );
      if (randomSequence === 0) {
        randomPassword +=
          numbers[randomNumber] +
          alphabets[randomAlphabet] +
          specialCharacters[randomCharacters];
      } else if (randomSequence === 1) {
        randomPassword +=
          alphabets[randomAlphabet] +
          numbers[randomNumber] +
          specialCharacters[randomCharacters];
      } else {
        randomPassword +=
          specialCharacters[randomCharacters] +
          alphabets[randomAlphabet] +
          numbers[randomNumber];
      }
    }
    setPassword(randomPassword);
  };

  function convertDateFormat(dateString) {
    if(dateString){
      const parts = dateString.split("/");
      const newDateFormat = `${parts[2]}-${parts[1]}-${parts[0]}`;
      return newDateFormat;
    }
}

  useEffect(() => {
    if (window.location.pathname.includes("single-user/update")) {
      setFormType("update");
      const userData = JSON.parse(sessionStorage.user);
      firstNameRef.current.value = userData.firstName;
      lastNameRef.current.value = userData.lastName;
      empIdRef.current.value = userData.empId;
      departmentRef.current.value = userData.department;
      usergroupRef.current.value = userData.role;
      shiftRef.current.value = userData.shift;
      emailRef.current.value = userData.email;
      setSelectedDepartment(userData.department);
      setSelectedShift(userData.shift);
      const selectedDepartmentData = departments.find(department => department.name === userData.department);
      const selectedShiftData = selectedDepartmentData?.shifts.find(shift => shift.name === userData.shift);
      setShiftTimings(selectedShiftData?.shiftTimings || []);
      setShiftTime(userData.shiftTiming);
      dobRef.current.value = convertDateFormat(userData.dateOfBirth);
      dojRef.current.value = convertDateFormat(userData.dateOfJoining);
      setDateOfBirth(userData.dateOfBirth);
      setDateOfJoining(userData.dateOfJoining);
    }
  }, [departments]);
  
  
  useEffect(() => {
    if (shiftTime) {
      shiftTimingsRef.current.value = shiftTime;
    }else{
      shiftTimingsRef.current.value="default";
    }
  }, [shiftTimings]);
  
  return (
    <form className="single-user-form" onSubmit={handleSubmit}>
          {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}

      <p style={{ fontSize: "1.3rem", color: "#a4b3ba" }}>
        {formType === "register" ? "Single User" : "User Update"}
      </p>

      {showPopup ? (
        <div className="user-reg-popup-container">
          <div className="single-user-reg-popup">
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>

              <button
                className="close-button"
                onClick={() => setShowPopup(!showPopup)}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />

            <div className="user-reg-popup-content">
              <div className="form-fields-container">
                <div className="d-flex flex-column">
                  <div className="d-flex">
                    <p className="labels">First Name:</p>
                    <p className="values">{firstNameRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Last Name:</p>
                    <p className="values">{lastNameRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Employee ID:</p>
                    <p className="values">{empIdRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Email:</p>
                    <p className="values">{emailRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">User Group:</p>
                    <p className="values">{usergroupRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Department:</p>
                    <p className="values">{departmentRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Shift Timings:</p>
                    <p className="values">{shiftTimingsRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Shift:</p>
                    <p className="values">{shiftRef.current.value}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Date of Joining:</p>
                    <p className="values">{dateOfJoining}</p>
                  </div>

                  <div className="d-flex">
                    <p className="labels">Date of Birth:</p>
                    <p className="values">{dateOfBirth}</p>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center pt-2">
                <button
                  className="generate-btn"
                  onClick={
                    formType === "register"
                      ? handleUserRegistration
                      : handleUserUpdate
                  }
                  type="submit"
                >
                  Confirm
                </button>
                <button
                  className="generate-btn ms-2"
                  onClick={() => setShowPopup(false)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="firstName">
              First Name
            </label>
            <input
              type="text"
              ref={firstNameRef}
              id="firstName"
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="lastName">
              Last Name
            </label>
            <input
              type="text"
              ref={lastNameRef}
              id="lastName"
              className="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-outline">
            <div className="col ms-0 ps-0">
              <div className="form-outline ">
                <label className="form-label" htmlFor="empId">
                  Employee ID
                </label>
                <input
                  type="text"
                  ref={empIdRef}
                  id="empId"
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="department">
              Department
            </label>
            <select id="department" className="form-select" ref={departmentRef} onChange={handleDepartmentChange}>
              <option value="default">Select Department</option>
              {departments.map((department, index) => (
                <option key={index} value={department.name}>
                  {department.name}
                </option>
              ))}
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="email">
              User Group
            </label>
            <select id="user-group" className="form-select" ref={usergroupRef}>
              <option value="Administration" disabled className="p-5">
                Select an option
              </option>
              {/* <option value="Super Admin">Super Admin</option>
              <option value="Human Resource">Human Resource</option>
              <option value="Digital Marketing">Digital Marketing</option> */}
              <option value="user">User</option>
              {/* <option value="Management Information System">
                Management Information System
              </option>
              <option value="Employee">Employee</option> */}
            </select>
            <div className="arrow-down"></div>
          </div>
        </div>
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="region">
              Shift
            </label>
              <div>
              <select id="shift" className="form-select" ref={shiftRef} onChange={handleShiftChange} required>
                <option value="default">Select Shift</option>
                {departments
                  .find(department => department.name === selectedDepartment)
                  ?.shifts.map((shift, index) => (
                    <option key={index} value={shift.name}>
                      {shift.name}
                    </option>
                  ))}
              </select>
              </div>
            {/* <select
              id="region"
              className="form-select"
              ref={regionRef}
              onChange={handleRegionChange}
              required
            >
              {region.map((item, index) => {
                return (
                  <option key={index} value={item} className="p-5">
                    {item}
                  </option>
                );
              })}
            </select> */}
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              ref={emailRef}
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="shift">
              Shift timings
            </label>
            <select id="shiftTimings" className="form-select" ref={shiftTimingsRef} onChange={handleShiftTimingChange} required>
              <option value="default">Select Shift Timings</option>
              {shiftTimings.map((timing, index) => (
                <option key={index} value={timing}>
                  {timing}
                </option>
              ))}
            </select>

            <div className="arrow-down"></div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col">
          <div className="form-outline">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <div className="input-group mb-3">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                aria-label="Password"
                aria-describedby="button-addon2"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="btn"
                type="button"
                id="button-addon2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <BsEyeSlash /> : <BsEye />}
              </button>
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="generate-btn"
                onClick={autoGeneratePassword}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="form-outline"> 
            <label className="form-label" htmlFor="DOB">
              Date of birth
            </label>
            <div className="input-group mb-3">
              <input
                className="date-input"
                ref={dobRef}
                type="date"
                id="dobInput"
                onChange={handleDOBChange}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-outline"> 
              <label className="form-label" htmlFor="DOJ">
                Date of Joining
              </label>
              <div className="input-group mb-3">
                <input
                  className="date-input"
                  ref={dojRef}
                  type="date"
                  id="dojInput"
                  onChange={handleDOJChange}
                  required
                />
              </div>
          </div>
        </div>
        <div className="col"></div>
      </div>
      <center>
        {isVisible ? <button className="generate-btn mt-2">Submit</button> : null}
      </center>
    </form>
  );
}

export default SingleUser;
