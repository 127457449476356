import axios from "axios";
import TokenService from "../services/token.service";

const instance = axios.create({
    baseURL:`https://${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        'Authorization' : 'Bearer ' + TokenService.getLocalAccessToken()
    }
});

instance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

let n=0;
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
  
          try {
            // console.log("Hello")
            let rs
           n++;
          //  console.log(n)
           if(n<4){
             rs = await instance.post("/auth/refresh-tokens", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
           
          } else {
            localStorage.clear()
            window.location.replace("/auth/login")
          }
  
            
            TokenService.updateLocalAccessToken(rs.data);
  
            return instance(originalConfig);
          } catch (_error) {
         console.log(_error)
          
          
            // return Promise.reject(_error);
          }
        }
      }
  
      return Promise.reject(err);
    }
  );
  export default instance;