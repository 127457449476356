import alt_axios from "axios";
import axios from "../config/axios";
import TokenService from "../services/token.service";

class CommonService {
  sendFeedback = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/feedback/feedback`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response.data;
  };

  getFeeback = async (feedbacksPerPage, pageNumber) => {
    const url = `https://${process.env.REACT_APP_API_URL}/feedback/get?limit=${feedbacksPerPage}&page=${pageNumber}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };
}

export default new CommonService();
