import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FaGreaterThan,
  FaEdit,
  FaLessThan,
  FaTrashAlt,
  FaUserAlt,
  FaSearch,
  FaUsersCog,
} from "react-icons/fa";
import {BiSolidUniversalAccess} from "react-icons/bi";
import { RiFilter2Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import UserRegistrationContent from "../../components/UserRegistration/UserRegistrationContent";
import AccessManagementContent from "../../components/AccessManagement/AccessMangement-Content";
// import UserRegistrationContent from "../UserRegistration/UserRegistrationContent";
function AccessManagement() {
    const [activeLink, setActiveLink] = useState("");
    const dispatch = useDispatch();
  
    const changeActiveLink = () => {
      const pathname = window.location.pathname;
      if (pathname.includes("access-control")) {
        setActiveLink("single-user");
      } 
    };
    useEffect(() => {
      changeActiveLink();
    }, [window.location.pathname]);
  return (
    <div className="d-flex user-registration-container flex-column">
    <div className="d-flex justify-content-center button-list"></div>
    <div className="user-registration-outer mx-auto">
      <div className="user-registration-inner mt-3">
        <div className="indicator-container">
          <div>
            <BiSolidUniversalAccess className="icon" />
          </div>
          <div className="text">User Management</div>
        </div>
        {activeLink === "access-control" && (
          <NavLink to={"/access-management/access-control"}>
            {/* <Button
          text={"Single User"}
          // bgColor="#B5D3D1"
        /> */}
          </NavLink>
        )}
       
        <AccessManagementContent />
      </div>
    </div>
  </div>
  )
}

export default AccessManagement