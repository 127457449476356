import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarNav from "./Sidebar-Nav";
import { logout } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import { Link } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import logo from '../../assets/ALOIS_SOLUTIONS_LOGO.png'

function Sidebar() {
  let dispatch = useDispatch();
  useEffect(() => {
    // document.querySelector('body').classList.add('app-init');

    var handleSidebarMenuToggle = function (menus) {
      menus.map(function (menu) {
        menu.onclick = function (e) {
          e.preventDefault();
          var target = this.nextElementSibling;

          menus.map(function (m) {
            var otherTarget = m.nextElementSibling;
            if (otherTarget !== target) {
              otherTarget.style.display = "none";
              otherTarget.closest(".menu-item").classList.remove("expand");
            }
            return true;
          });

          var targetItemElm = target.closest(".menu-item");

          if (
            targetItemElm.classList.contains("expand") ||
            (targetItemElm.classList.contains("active") &&
              !target.style.display)
          ) {
            targetItemElm.classList.remove("expand");
            target.style.display = "none";
          } else {
            targetItemElm.classList.add("expand");
            target.style.display = "block";
          }
        };
        return true;
      });
    };

    var menuBaseSelector = ".app-sidebar .menu > .menu-item.has-sub";
    var submenuBaseSelector = " > .menu-submenu > .menu-item.has-sub";

    // menu
    var menuLinkSelector = menuBaseSelector + " > .menu-link";
    var menus = [].slice.call(document.querySelectorAll(menuLinkSelector));
    handleSidebarMenuToggle(menus);

    // submenu lvl 1
    var submenuLvl1Selector = menuBaseSelector + submenuBaseSelector;
    var submenusLvl1 = [].slice.call(
      document.querySelectorAll(submenuLvl1Selector + " > .menu-link")
    );
    handleSidebarMenuToggle(submenusLvl1);

    // submenu lvl 2
    var submenuLvl2Selector =
      menuBaseSelector + submenuBaseSelector + submenuBaseSelector;
    var submenusLvl2 = [].slice.call(
      document.querySelectorAll(submenuLvl2Selector + " > .menu-link")
    );
    handleSidebarMenuToggle(submenusLvl2);
  });

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-mobile-toggled");
  };
  const { toggleShowSidebar, showSidebar } = useDOMStatesContext();

  return (
    <React.Fragment>
      <div
        id="sidebar"
        className="app-sidebar"
        style={showSidebar ? { display: "block" } : {}}>

        <img alt="ALOISlogoblack12672" src={logo} width="155px"  className="logo"/>

        <div
          style={
            showSidebar
              ? {
                  position: "absolute",
                  top: "25px",
                  right: "20px",
                }
              : { display: "none" }
          }
        >
          <AiFillCloseCircle
            className="close-btn"
            style={{ fill: "#ff914e" }}
            onClick={toggleShowSidebar}
          />
        </div>
        <PerfectScrollbar
          className="app-sidebar-content"
          options={{ suppressScrollX: true }}
        >
          <SidebarNav />
        </PerfectScrollbar>
      </div>
      <button
        className="app-sidebar-mobile-backdrop"
        onClick={toggleAppSidebarMobile}
      ></button>
    </React.Fragment>
  );
}

export default Sidebar;
