import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {logout} from "../../features/userSlice";

const Logout = () => {
    const dispatch = useDispatch();
    
    useEffect(()=>{
        dispatch(logout()) 
        window.location = '/auth/login'
    })
  return (
    <div>logout</div>
  )
}

export default Logout