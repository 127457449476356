import React from "react";
import buzz_image from "../../assets/images/buzz-image.png";
import ImageUtil from "../../utilities/ImageUtil";

function BuzzCard(props) {
  const { month, year, image } = props;

  return (
    <div className="buzz-card-container my-3">
      <img
        src={image}
        alt="buzz-image"
        className="buzz-image"
      />
      <div className="d-flex flex-column justify-content-center">
        <p className="buzz-text" style={{ textTransform: "capitalize" }}>
          {month}
        </p>
        <p className="buzz-text">{year}</p>
      </div>
    </div>
  );
}

export default BuzzCard;
