import TokenService from "./token.service";
import axios from "../config/axios";
import alt_axios from "axios";

class JobDescriptionService {
  createJobDescription = async (formData) => {
    const response = await axios.post(`/jobDescription/create`, formData,{ 
    headers: {
      "Content-Type": "multipart/form-data",
    } 
  });
    console.log("Service", response);
    return response.data;
  };
  getRandomJDByDomain = async (domain, answeredQuestions) => {
    console.log(answeredQuestions)

    const response = await axios.get(`/jobDescription/random-jd?domain=${domain}&answeredQuestions=${JSON.stringify(answeredQuestions)}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
  getJDs = async (domain, pageNo = 1) => {
    let url = `/jobDescription?`
    if(domain){
      url +=  "&domain="+ domain + "&page="+pageNo + "&limit=1" 
    } else {
      url += "&limit=5000"
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

}

export default new JobDescriptionService();
