import React, { useEffect, useContext, useState, useRef } from "react";
import { Helmet } from "react-helmet";
// import './Login.css'
import { Navigate } from "react-router-dom";
import { AppSettings } from "../../config/app-setting";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
import axios from "../../config/axios";
import Footer from "../../components/Footer/Footer";
import { Toast } from "bootstrap";
import logo from '../../assets/ALOIS_SOLUTIONS_LOGO.png'

const Login = (props) => {
  const [loginError, setLoginError] = useState("");
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const emailRef = useRef();
  const passRef = useRef();
  const dispatch = useDispatch();

  // useEffect(() => {
  // 	context.setAppHeaderNone(true);
  // 	context.setAppSidebarNone(true);
  // 	context.setAppContentClass('p-0');

  // 	return function cleanUp() {
  // 		context.setAppHeaderNone(false);
  // 		context.setAppSidebarNone(false);
  // 		context.setAppContentClass('');
  // 	};

  // 	// eslint-disable-next-line
  // }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      let res = await axios.post("/auth/login", {
        email: emailRef.current.value,
        password: passRef.current.value,
      });
      let data = res.data;

      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("tokens", JSON.stringify(data.tokens));
      dispatch(login(data.user));
    } catch (err) {
      
      if (err.message === "Request failed with status code 401") {
        setLoginError("Invalid Email or Password ! \n Please try again!");
      } else if(err.response.data =="Please kindly check your email for latest email and password!"){
        setLoginError(err.response.data)
      } else {
        setLoginError("Server Error! \n Please try again later!");
      }
      // setLoginError(err.message)
      // console.log(err.code);
      const toast1 = document.getElementById("toast-1");
      const toast = new Toast(toast1);
      toast.show();
    }

    //   window.location.href = '/dashboard';
  }
  if (redirect) {
    return <Navigate to="/" />;
  }
  return (
    <div className="login">
      <div>
        <div className="toasts-container position-absolute top-0 mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-1">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              {/* <p className="text-success">{success}</p> */}
              <p className="text-danger">{loginError}</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="login-card d-flex">
        <div className="login-text col-7">
          <img
            alt="ALOISlogoblack12672"
            src={logo}
            width="155px"
            className="logo"
          />
          <form onSubmit={handleSubmit}>
            <div className="fs-2 fw-bolder text-dark pt-5">
              <span>Login</span>
            </div>
            <div className="fs-1.125">
              <span>Please enter your details</span>
            </div>
            <input
              ref={emailRef}
              type="email"
              placeholder="Email"
              className="my-3 px-2 py-1 mt-5"
            ></input>
            <input
              ref={passRef}
              type="password"
              placeholder="Password"
              className="px-2 py-1"
            ></input>
            <div className="d-flex flex-column">
            <button
              type="submit"
              className="button mt-5 px-2 py-1 fw-bolder text-white"
            >
              Login
            </button>
            <a className="w-75 text-center mt-2 me-2 pe-1" href="/auth/forgot-password">Forgot Password?  </a>
            </div>
          </form>
          {/* <div style={{ position: "absolute", bottom: "2rem", width:"38%", marginLeft:"-50px" }}>
            <Footer />
          </div> */}
        </div>

        <div>
          <img
            alt="unsplashLpbyDENbQQg248"
            src="/assets/output-onlinepngtools.png"
            className="login-element"
          />
        </div>
      </div>
    </div>
  );
};
export default Login;
