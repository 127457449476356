import alt_axios from "axios";
import TokenService from "../services/token.service";

class EssService {
  skillHighlight = async (data) => {
    // change the base url to REACT_APP_API_URL when deploying.
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/utility/skillHighlighter`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
  interviewQandA = async (data) => {
    // change the base url to REACT_APP_API_URL when deploying.
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/utility/createQuestionAnswer`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    return response.data;
  };
}

const essService = new EssService();
export default essService;
