import axios from "../config/axios";

class MinioService {
  SearchFile = async (bucket, eid) => {
    try {
      const response = await axios.get("/minio/searchFile", {
        params: {
          bucket,
          eid,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  UploadFile = async (thumbnail, content, folderName, buzzName) => {
    try {
      const formData = new FormData();
      thumbnail && formData.append("thumbnail", thumbnail);
      content && formData.append("content", content);
      folderName && formData.append("folderName", folderName);
      buzzName && formData.append("buzzName", buzzName);

      const response = await axios.post(`/minio/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  UploadSingleFile = async (image, folderName) => {
    try {
      const formData = new FormData();
      image && formData.append("image", image);
      folderName && formData.append("folderName", folderName);

      const response = await axios.post(`/minio/single-upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default new MinioService();
