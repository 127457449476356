import React from "react";
import Button from "./Button";
import { NavLink, useNavigate } from "react-router-dom";
import { FaLessThan } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";

function EssBackBtn() {
  let navigate = useNavigate()
  let navToAssist = ()=>{
    navigate('/assist')
  }
  return (
     
    <span onClick={navToAssist} className="ess-back-btn">
      
      <IoIosArrowBack className="icon "  style={{marginBottom : "10px"}} />
    </span>
  );
}

export default EssBackBtn;
