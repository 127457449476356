import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import buzzService from "../../services/buzz.service";
import ImageUtil from "../../utilities/ImageUtil";
import { AiFillEdit, AiOutlineClose } from "react-icons/ai";
import dashboardService from "../../services/dashboard.service";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import { IoMdAdd } from "react-icons/io";
import { BiSolidTrash } from "react-icons/bi";
import licenceValidator from "../../permission/validate";

function NoticeBoard() {
  const [latestBuzz, setLatestBuzz] = useState([]);
  const [showNoticeForm, setShowNoticeForm] = useState(false);
  const [showEditNoticeForm, setShowEditNoticeForm] = useState(false);
  const [showManageNoticeForm, setShowManageNoticeForm] = useState(false);
  const [noticeData, setNoticeData] = useState({})
  const [userType, setUserType] = useState("user");
  const [isvisible, setIsVisible] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  const [noticeDeleteDate, setNoticeDeleteDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [noticeList, setNoticeList] = useState([]);
  const [noticeDeleteList, setNoticeDeleteList] = useState([]);
  const titleRef = useRef(null);
  const linkRef = useRef("");

  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const handleNoticeStartDateChange = (value) => {
    let tempDate = new Date().toLocaleDateString("en-CA");
    if (value < tempDate) {
      setStartDateError("Start date cannot be a past date");
    } else if (value > endDate) {
      setStartDateError("Start date cannot be after end date");
    } else {
      setStartDateError("");
    }
    if (value > endDate) {
      setEndDateError("End date cannot be before start date");
    } else {
      setEndDateError("");
    }
    setStartDate(value);
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "home");
    setIsVisible(validator.isReadable("notice_board"));
  }, []);

  const handleNoticeEndDateChange = (value) => {
    let tempDate = new Date().toLocaleDateString("en-CA");
    if (startDate > value) {
      setEndDateError("End date cannot be before start date");
    } else if (value < tempDate) {
      setEndDateError("End date cannot be a past date");
    } else {
      setEndDateError("");
    }
    if (startDate > value) {
      setStartDateError("Start date cannot be after end date");
    } else {
      setStartDateError("");
    }
    setEndDate(value);
  };

  const getLatestBuzz = async () => {
    try {
      const data = await buzzService.getBuzzList1();
      if (data.results.length > 2)
        setLatestBuzz([data.results[0], data.results[1], data.results[2]]);
      if (data.results.length === 2)
        setLatestBuzz([data.results[0], data.results[1]]);
      if (data.results.length === 1) setLatestBuzz([data.results[0]]);

    } catch (error) {}
  };

  const getLatestNoticeBoard = async (date) => {
    try {
      const result = await dashboardService.getLatestNoticeBoard(date);
      setNoticeList(result);
      // console.log("result", result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (startDateError.length === 0 && endDateError.length === 0) {
      try {
        const result = await dashboardService.createNotice({
          noticeTitle: titleRef.current.value,
          link: linkRef.current.value,
          startDate: startDate,
          endDate: endDate,
        });
        setErrorNotific("");
        setSuccessNotific("Notice board updated");
        getNoticeForDate(new Date().toLocaleDateString("en-CA"));
      } catch (err) {
        setErrorNotific("Notice board update failed");
        setSuccessNotific("");
        console.log(err);
      }
      setShowNoticeForm(false);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const getNoticeForDate = async (date) => {
    // setNoticeDeleteDate(date);
    try {
      const result = await dashboardService.getLatestNoticeBoard(date);
      setNoticeDeleteList(result);
      // console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
const editNoticePopUp = async (item) => {
  // 
  setShowEditNoticeForm(!showEditNoticeForm)
  setShowNoticeForm(!showNoticeForm)
  setShowManageNoticeForm(!showManageNoticeForm)
  setNoticeData(item)
  
  const dateObject = new Date(item.startDate);

// Format the date as YYYY-MM-DD
const formattedDate = dateObject.toISOString().split("T")[0];
const dateObject1 = new Date(item.endDate);

// Format the date as YYYY-MM-DD
const formattedDate1 = dateObject1.toISOString().split("T")[0];
  setStartDate(formattedDate)
  setEndDate(formattedDate1)
  
 
  
}
useEffect(()=>{
  if(titleRef?.current && linkRef?.current){
    titleRef.current.value = noticeData.noticeTitle;
    linkRef.current.value = noticeData.link
  }
},[noticeData])
const editNotice = async (e) => {
  e.preventDefault();
  if (startDateError.length === 0 && endDateError.length === 0) {
    try {
      const result = await dashboardService.editNotice({
        id:noticeData.id,
        body: {
          noticeTitle: titleRef.current.value,
          link: linkRef.current.value,
          startDate: startDate,
          endDate: endDate,
          status:1
        }
      
      });
      setErrorNotific("");
      setSuccessNotific("Notice board updated");
      getLatestNoticeBoard(new Date().toLocaleDateString("en-CA"));
    } catch (err) {
      setErrorNotific("Notice board update failed");
      setSuccessNotific("");
      console.log(err);
    }
    getNoticeForDate(new Date().toLocaleDateString("en-CA"));
    editNoticePopUp({})
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }
}
  const deleteNotice = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this notice?"
    );
    if (confirmed) {
      try {
        const result = await dashboardService.deleteNotice({ id: id });
      } catch (error) {
        console.log(error);
      }
    }
    getNoticeForDate(noticeDeleteDate);
    getLatestNoticeBoard(new Date().toLocaleDateString("en-CA"));
  };

  useEffect(() => {
    getLatestBuzz();
    getLatestNoticeBoard(new Date().toLocaleDateString("en-CA"));
  }, []);
  useEffect(() => {
    let user = JSON.parse(localStorage.user).role;
    setUserType(user);
  }, []);

  return (
    <div className="d-flex flex-column notice">
      <div className="notice-board-container">
        <div className="header text-center fw-bold d-flex justify-content-between">
          <div>Notice Board</div>
          {isvisible ? (
            <div className="d-flex">
              <div
                className="icon px-1"
                onClick={() => setShowNoticeForm(!showNoticeForm)}
                role="button"
              >
                <IoMdAdd style={{ width: "22px", height: "22px" }} />
              </div>
              <div
                className="icon px-1"
                role="button"
                onClick={() => {
                  setShowManageNoticeForm(!showManageNoticeForm);
                  getNoticeForDate(new Date().toLocaleDateString("en-CA"));
                }}
              >
                <AiFillEdit />
              </div>
            </div>
          ) : null}
        </div>
        {showAlert ? (
          <div className="alert-container">
            <div className="upper-section">
              <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
              <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
                <AiOutlineClose className="icon" style={{ fill: "black" }} />
              </span>
            </div>
            <hr
              style={{
                display: "block !important",
                height: "1px !important",
                border: "0 !important",
                borderTop: "1px solid gray !important",
              }}
            />
            <div className="lower-section pt-2">
              <p className="text-danger">{errorNotific}</p>
              <p className="text-success">{successNotific}</p>
            </div>
          </div>
        ) : null}
          <ul>
            {noticeList &&
              noticeList.map((item, index) => {
                if (item.status) {
                  if (item.link.length > 2)
                    return (
                      <NavLink
                        to={item.link}
                        target="_blank"
                        className="d-flex notice-board-link"
                        key={index}
                      >
                        <div>🔹</div>
                        <li> {item.noticeTitle} </li>
                      </NavLink>
                    );
                  else
                    return (
                      <div className="d-flex" key={index}>
                        <div>🔹</div>
                        <li> {item.noticeTitle} </li>
                      </div>
                    );
                } else return null;
              })}
          </ul>
      </div>

      {showNoticeForm ? (
        <div className="notice-popup-form">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">{showEditNoticeForm ?"Edit Notice":"Create Notice"}</span>
              <button
                className="close-button"
                onClick={() => {
                  setShowNoticeForm(!showNoticeForm);
                  if(showEditNoticeForm){
                    editNoticePopUp({})
                  }
                  setEndDateError("");
                  setStartDateError("");
                }}
              >
                &#10005;
              </button>
            </div>
            <hr style={{ borderTop: "3px dotted black" }} />
            <div className="popup-content">
              <form onSubmit={(e)=> {
                        if(showEditNoticeForm){
                          editNotice(e)
                          } else {
                            handleUpload(e)
                          }}} encType="multipart/form-data">
                <div className="form-fields-container">
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="notice-title">
                          Notice Title*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="notice-title"
                          placeholder="Notice title"
                          ref={titleRef}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="link">
                          Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="link"
                          placeholder="link"
                          ref={linkRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="date">
                          Start date*
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          id="date"
                          value={startDate}
                          onChange={(e) =>
                            handleNoticeStartDateChange(e.target.value)
                          }
                        />
                        <span className="text-danger h6">{startDateError}</span>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="date">
                          End date*
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          id="date"
                          value={endDate}
                          onChange={(e) =>
                            handleNoticeEndDateChange(e.target.value)
                          }
                        />
                        <span className="text-danger h6">{endDateError}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" mt-2">
                  <button className="generate-btn mx-auto mt-3 p-1" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}

      {/* Edit Notice Form */}

      {showManageNoticeForm ? (
        <div className="notice-popup-form">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">Delete Notice</span>
              <button
                className="close-button"
                onClick={() => setShowManageNoticeForm(!showManageNoticeForm)}
              >
                &#10005;
              </button>
            </div>
            <hr style={{ borderTop: "3px dotted black" }} />
            <div className="input-date d-flex justify-content-end">
              <input
                type="date"
                style={{ border: "1px solid #175572", borderRadius: "7px" }}
                value={noticeDeleteDate}
                onChange={(e) => {
                  setNoticeDeleteDate(e.target.value);
                  getNoticeForDate(e.target.value);
                }}
              />
            </div>
            <div className="popup-content">
              <div className="d-flex flex-column">
                {noticeDeleteList.map((item, index) => {
                  if (item.status) {
                    return (
                      <div className="d-flex justify-content-between py-2">
                        <div class="notice-title px-1">
                          {" "}
                          🔹{item.noticeTitle}
                        </div>
                        <div className="d-flex">
                        <div
                          className="delete-btn px-2"
                          role="button"
                          onClick={() => editNoticePopUp(item)}
                        >
                          <AiFillEdit  />
                        </div>
                        <div
                          className="delete-btn px-2"
                          role="button"
                          onClick={() => deleteNotice(item.id)}
                        >
                          <BiSolidTrash style={{ fill: "red" }} />
                        </div>
                      </div>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* // latest buzz */}
      <div className="latest-buzz-container">
        <div className="header">Latest Buzz</div>
        <div className="d-flex flex-column">
          {latestBuzz.length > 0
            ? latestBuzz.map((item) => {
              
              if(item){ return (
                  
                  <NavLink
                    to={"/buzz"}
                    className="latest-buzz-card-container my-1"
                  >
                   {item?.featureImage?<img
                      src={item.featureImage}
                      alt="buzz-image"
                      className="buzz-image"
                    />:<></>}
                    <div className="d-flex flex-column justify-content-center w-25">
                      <p
                        className="buzz-text pe-2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.month}
                      </p>
                      <p
                        className="buzz-text pe-2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.year}
                      </p>
                    </div>
                  </NavLink>
                  
                ) 
                   } else {return <></>}
              })
            : null}
          {/* <BuzzCard /> */}
          {/* <BuzzCard /> */}
        </div>
      </div>
    </div>
  );
}

export default NoticeBoard;
