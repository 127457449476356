import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Papa from "papaparse";
import { AiOutlineClose } from "react-icons/ai";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaEdit,
  FaTrashAlt,
  FaUserAlt,
  FaSearch,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { HiMiniAdjustmentsVertical } from "react-icons/hi2";
import licenceValidator from "../../permission/validate";
import userRegistrationService from "../../services/userRegistration.service";
import scheduledUsersService from "../../services/scheduledUsers.service";
import UserRegistrationPopup from "./UserRegistrationPopup";

function ManageUser() {
  const visiblePages = 5;
  const usersPerPage = 10;
  const fixedColumns = ["First Name", "Last Name"];
  const dynamicColumns = [
    "Employee ID",
    "Department",
    "Shift",
    "Email",
    "Shift Timing",
  ];
  const defaultColumns = ["Department", "Shift Timing"];
  const columnKeyMapping = {
    "First Name": "firstName",
    "Last Name": "lastName",
    "Employee ID": "empId",
    Department: "department",
    Shift: "shift",
    Email: "email",
    "Shift Timing": "shiftTiming",
    "DOB":"dateOfBirth",
    "DOJ":"dateOfJoining"
  };

  const [userList, setUserList] = useState([]);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [initialFilteredUserList, setInitialFilteredUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [unpagedFilteredUserList, setUnpagedFilteredUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftTimings, setShiftTimings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isdeletable, setIsDeletable] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [schedule, setSchedule] = useState();
  const [usersCountByDepartment, setUsersCountByDepartment] = useState({});
  const [dynamicTableColumns, setDynamicTableColumns] =
    useState(defaultColumns);
  const [showDynamicDropdown, setShowDynamicDropdown] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredShiftTimings, setFilteredShiftTimings] = useState([]);
  const [scheduledDeleteUsers, setScheduledDeleteUsers] = useState([]);
  const [searchInputFocused, setSearchInputFocused] = useState(false);

  const departmentRef = useRef(null);
  const shiftTimingRef = useRef(null);
  const shiftRef = useRef(null);
  const timeRef = useRef(null);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const dynamicDropdownRef = useRef(null);
  const initialFilteredUserListCalled = useRef(false);
  const tableRef = useRef(null);

  const handleDeleteSchedule = async (e) => {
    e.preventDefault();

    const confirmation = window.confirm(
      "Are you sure you want to delete this schedule?"
    );

    if (!confirmation) {
      return;
    }

    try {
      await scheduledUsersService.deleteSchedule(selectedUserId);

      getSchedules();

      setShowAlert(true);
      setSuccessNotific("Schedule deleted successfully!");
      setErrorNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } catch (err) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("Error in deleting schedule!");
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }

    setSchedulePopup(false);
  };

  const dropdown = async () => {
    try {
      const response = await userRegistrationService.getUsers();
      const data = response.results;

      const uniqueDepartments = [
        ...new Set(data.map((item) => item.department)),
      ];
      const uniqueShifts = [...new Set(data.map((item) => item.shift))];
      const uniqueShiftTimings = [
        ...new Set(data.map((item) => item.shiftTiming)),
      ];

      setDepartments(uniqueDepartments);
      setShifts(uniqueShifts);
      setShiftTimings(uniqueShiftTimings);
    } catch (error) {
      console.error("Error Fetching data ", error);
    }
  };

  const handleDeparmentChange = async (e) => {
    const { value } = e.target;

    if (value === "default") {
      const uniqueShifts = [...new Set(userList.map((item) => item.shift))];

      setShifts(uniqueShifts);
      shiftRef.current.value = "default";

      const uniqueShiftTimings = [
        ...new Set(userList.map((item) => item.shiftTiming)),
      ];
      setShiftTimings(uniqueShiftTimings);
      shiftTimingRef.current.value = "default";
    }

    if (departments.includes(value)) {
      const shiftsArray = [];
      userList.filter((item) => {
        if (item.department === value) [shiftsArray.push(item.shift)];
      });

      const uniqueShifts = [...new Set(shiftsArray)];

      setShifts(uniqueShifts);
      shiftRef.current.value = "default";

      const shiftTimingsArray = [];
      userList.filter((item) => {
        if (item.department === value)
          [shiftTimingsArray.push(item.shiftTiming)];
      });

      const uniqueShiftTimings = [...new Set(shiftTimingsArray)];
      setShiftTimings(uniqueShiftTimings);
      setFilteredShiftTimings(uniqueShiftTimings);
      shiftTimingRef.current.value = "default";
    }

    initialFilteredUserListCalled.current = false;
    filterUsers();
  };

  const handleRegionChange = async (e) => {
    const { value } = e.target;

    if (value == "default") {
      setShiftTimings(filteredShiftTimings);
    } else {
      const shiftTimingsArray = [];
      initialFilteredUserList.filter((item) => {
        if (item.shift === value) [shiftTimingsArray.push(item.shiftTiming)];
      });

      const uniqueShiftTimings = [...new Set(shiftTimingsArray)];
      setShiftTimings(uniqueShiftTimings);
    }

    shiftTimingRef.current.value = "default";
    filterUsers();
  };

  const filterUsers = (data) => {
    setSelectedRows([]);

    let updatedUserList = data || [...userList];

    if (userRef.current?.value) {
      const searchTerm = userRef.current.value
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim();

      // Use case: If user tries to search with full name
      if (searchTerm.includes(" ")) {
        const [firstName, lastName] = searchTerm.split(" ");

        if (searchTerm.split(" ").length <= 2) {
          updatedUserList = updatedUserList.filter(
            (item) =>
              ((item.firstName &&
                item.firstName.toLowerCase().includes(firstName)) ||
                (item.lastName &&
                  item.lastName.toLowerCase().includes(lastName))) &&
              item.firstName &&
              item.firstName.toLowerCase().includes(firstName) &&
              item.lastName &&
              item.lastName.toLowerCase().includes(lastName)
          );
        } else {
          updatedUserList = [];
        }
      } else {
        updatedUserList = updatedUserList.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().includes(searchTerm)) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchTerm)) ||
            (item.email && item.email.toLowerCase().includes(searchTerm))
        );
      }
    }

    if (departmentRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.department &&
          item.department.toLowerCase() ==
            departmentRef.current.value.toLowerCase()
      );
    }

    if (shiftRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.shift &&
          item.shift.toLowerCase() == shiftRef.current.value.toLowerCase()
      );
    }

    if (shiftTimingRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.shiftTiming &&
          item.shiftTiming.toLowerCase() ==
            shiftTimingRef.current.value.toLowerCase()
      );
    }

    setFilteredUserList(updatedUserList);
    setUnpagedFilteredUserList(updatedUserList);

    // We need user list according to the department we have selected and independent of shift change.
    if (!initialFilteredUserListCalled.current) {
      setInitialFilteredUserList(updatedUserList);
      initialFilteredUserListCalled.current = true;
    }

    const tempTotalPages = Math.max(
      1,
      Math.ceil(updatedUserList.length / usersPerPage)
    );
    setTotalPages(tempTotalPages);

    setCurrentPage(1);
    paginate(1, updatedUserList);
    adjustPages(1, tempTotalPages);
  };

  const paginate = (currPage, data = []) => {
    // Scroll to the top of the table whenever page number is changed
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }

    const start = (currPage - 1) * usersPerPage;
    const end = start + usersPerPage;

    if (data.length > 0) {
      setFilteredUserList(data.slice(start, end));
    } else {
      !userRef.current.value &&
        !shiftRef.current.value &&
        setFilteredUserList(userList.slice(start, end));
    }
  };

  const getUsers = async (pageNo) => {
    const data = await userRegistrationService.getUsers();
    const tempTotalPages = Math.max(
      1,
      Math.ceil(data.totalResults / usersPerPage)
    );
    setTotalPages(tempTotalPages);

    setCurrentPage(pageNo);
    paginate(pageNo, data.results);

    setUserList(data.results);
    setInitialFilteredUserList(data.results);
    setUnpagedFilteredUserList(data.results);

    initialFilteredUserListCalled.current = false;
    filterUsers(data.results);
  };

  const handleDelete = async (userData) => {
    if (selectedRows.length === 0) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("No user is selected to delete");
      setTimeout(() => {
        setShowAlert(false);
      }, [2000]);
    } else {
      const userIdsArray = userData.map((item) => item.id);
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this user?"
      );

      if (shouldDelete) {
        try {
          setSelectedRows([]);

          await userRegistrationService.deleteMultipleUsers({
            userIdsArray,
          });

          getUsers(currentPage);

          setShowAlert(true);
          setSuccessNotific(
            `User${selectedRows.length > 1 ? "s" : ""} deleted successfully!`
          );
          setErrorNotific("");
          setTimeout(() => {
            setShowAlert(false);
          }, [2000]);
        } catch (error) {
          setShowAlert(true);
          setSuccessNotific("");
          setErrorNotific(
            `Error in deleting user${selectedRows.length > 1 ? "s" : ""}!`
          );
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      }
    }
  };

  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    } else {
      tempStart = 1;
      tempEnd = finalTotalPages;
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    setCurrentPage(currentPage);
  };

  const getSchedules = async () => {
    const schedules = await scheduledUsersService.getSchedules();
    setScheduledDeleteUsers(schedules.results);
  };

  const getSchedule = async (userId) => {
    const schedule = await scheduledUsersService.getSchedule(userId);

    if (schedule.results.length) {
      const data = schedule.results[0];
      const date = new Date(data.date);

      setSchedule(schedule.results[0]);

      dateRef.current.value = date.toISOString().split("T")[0];
      timeRef.current.value = data.time;
    } else {
      setSchedule(null);
    }
  };

  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleScheduledDelete = async (e) => {
    e.preventDefault();

    await scheduledUsersService.createSchedule({
      userId: selectedUserId,
      date: dateRef.current.value,
      time: timeRef.current.value,
    });

    getSchedules();

    setShowAlert(true);
    setSuccessNotific("User Delete Scheduled Succesfully!");
    setErrorNotific("");
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);

    setSchedulePopup(false);
  };

  const dynamicDropdownCheckbox = (item) => {
    const updatedColumns = dynamicTableColumns.includes(item)
      ? dynamicTableColumns.filter((selectedColumn) => selectedColumn !== item)
      : [...dynamicTableColumns, item];

    setDynamicTableColumns(updatedColumns);
  };

  const closeDynamicDropdown = (event) => {
    if (
      dynamicDropdownRef.current &&
      !dynamicDropdownRef.current.contains(event.target)
    ) {
      setShowDynamicDropdown(false);
    }
  };

  const selectAllRows = () => {
    setShowAlert(false);

    if (!filteredUserList.length) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("No user is available to select");
      setTimeout(() => {
        setShowAlert(false);
      }, [2000]);
    }

    const isItemSelected = !unpagedFilteredUserList.every((item) =>
      selectedRows.some((selectedItem) => selectedItem.id === item.id)
    );

    setSelectedRows(isItemSelected ? unpagedFilteredUserList : []);
  };

  const handleBodyCheckbox = (item) => {
    setShowAlert(false);

    const isItemSelected = selectedRows.some(
      (selectedItem) => selectedItem.id === item.id
    );

    const updatedSelectedRows = isItemSelected
      ? selectedRows.filter((selectedItem) => selectedItem.id !== item.id)
      : [...selectedRows, item];

    setSelectedRows(updatedSelectedRows);
  };

  const handleExport = () => {
    if (selectedRows.length === 0) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("No user is selected to export the data");
      setTimeout(() => {
        setShowAlert(false);
      }, [2000]);
    } else {
      const usersData = selectedRows.map((row) => {
        const rowData = {};

        [...fixedColumns, ...dynamicTableColumns,"DOB", "DOJ"].forEach((column) => {
          rowData[column] = row[columnKeyMapping[column]];
        });

        return rowData;
      });

      const csvData = Papa.unparse(usersData);
      const filename = `Exported_${
        selectedRows.length
      }_Users_With_${dynamicTableColumns.join("_")}.csv`;

      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const handleEmailWrap = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const firstPart = email.slice(0, atIndex);
      const secondPart = email.slice(atIndex);
      return (
        <>
          {firstPart}
          <br />
          {secondPart}
        </>
      );
    }
    return email;
  };

  const breakWordWithDash = (text) => {
    return text.split("-").map((word, index, array) => (
      <div key={index}>
        {word}
        {index !== array.length - 1 && "-"}
        {index !== array.length - 1 && <br />}
      </div>
    ));
  };

  useEffect(() => {
    if (userList.length !== 0) {
      const counts = {};

      userList.forEach((user) => {
        const department = user.department;
        counts[department] = (counts[department] || 0) + 1;
      });

      setUsersCountByDepartment(counts);
    }
  }, [userList]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "user_registration");
    setIsVisible(validator.isReadable("manage_user"));
    setIsDeletable(validator.isDelete("manage_user"));

    dropdown();
    getUsers(1);
    adjustPages();
    getSchedules();

    document.addEventListener("click", closeDynamicDropdown);
    return () => {
      document.removeEventListener("click", closeDynamicDropdown);
    };
  }, []);

  return (
    <div className="manage-user-table">
      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      )}

      <div className="upper-section">
        <div className="d-flex">
          <div className="input-group mb-3">
            <button
              className={`btn btn-outline-secondary search-btn ${
                searchInputFocused && "search-input-focused"
              }`}
              type="button"
              id="button-addon1"
              onClick={() => userRef.current.focus()}
            >
              <FaSearch className="mb-1" />
            </button>

            <input
              type="text"
              className="form-control search-input-user"
              placeholder="Search"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              ref={userRef}
              onChange={() => filterUsers()}
              onFocus={() => setSearchInputFocused(true)}
              onBlur={() => setSearchInputFocused(false)}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end gap-3">
          <div className="d-flex me-2">
            <div className="form-outline">
              <select
                id="department"
                className="form-select dropdown-styles"
                ref={departmentRef}
                onChange={handleDeparmentChange}
              >
                <option value="default" className="p-5">
                  All Departments ({userList.length})
                </option>
                {departments.map(
                  (item, index) => (
                    <option value={item} key={index}>
                      {item} ({usersCountByDepartment[item] || 0})
                    </option>
                  ),
                  []
                )}
              </select>
              <div className="arrow-down"></div>
            </div>
          </div>

          <div className="d-flex me-2">
            <div className="form-outline">
              <select
                id="region"
                className="form-select dropdown-styles"
                ref={shiftRef}
                onChange={handleRegionChange}
              >
                <option value="default">All Shifts</option>
                {shifts.map(
                  (item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ),
                  []
                )}
              </select>
              <div className="arrow-down"></div>
            </div>
          </div>

          <div className="d-flex me-2">
            <div className="form-outline">
              <select
                id="shift"
                className="form-select dropdown-styles"
                ref={shiftTimingRef}
                onChange={() => filterUsers()}
              >
                <option value="default">All Shift Timings</option>
                {shiftTimings.map(
                  (item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ),
                  []
                )}
              </select>
              <div className="arrow-down"></div>
            </div>
          </div>

          <div ref={dynamicDropdownRef}>
            <div
              className="cursor-pointer"
              onClick={() => setShowDynamicDropdown(!showDynamicDropdown)}
            >
              <HiMiniAdjustmentsVertical
                className={`table-column-filter ${
                  showDynamicDropdown && "filter-active"
                }`}
              />
            </div>

            {showDynamicDropdown && (
              <div className="dynamic-dropdown">
                {dynamicColumns.map((item, index) => (
                  <div key={index}>
                    <input
                      type="checkbox"
                      id={item}
                      checked={dynamicTableColumns.includes(item)}
                      onChange={() => dynamicDropdownCheckbox(item)}
                    />
                    <label htmlFor={item}> &nbsp; {item}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="action-row">
        <div className="d-flex">
          <div className="action-item-button" onClick={handleExport}>
            <FiDownload />
          </div>

          {isdeletable && (
            <td
              className="action-item-button"
              onClick={() => handleDelete(selectedRows)}
            >
              <FaTrashAlt />
            </td>
          )}
        </div>

        <div className="user-count">
          Selected User Count: {selectedRows.length}
        </div>
      </div>

      {schedulePopup && (
        <div className="uploadform-popup">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">Schedule User Delete</span>

              <button
                className="close-button"
                onClick={() => setSchedulePopup(!schedulePopup)}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "3px dotted black" }} />

            <div className="popup-content">
              <form
                encType="multipart/form-data"
                onSubmit={handleScheduledDelete}
              >
                <div className="form-fields-container">
                  <div className="row pb-4">
                    <div className="col-xl-6">
                      <div className="form-outline d-flex flex-column">
                        <label
                          className="form-label"
                          htmlFor="department"
                          style={{ marginTop: "10px" }}
                        >
                          Date:
                        </label>

                        {!schedule ? (
                          <input
                            min={new Date().toISOString().split("T")[0]}
                            ref={dateRef}
                            type="date"
                            required
                          />
                        ) : (
                          <input
                            min={new Date().toISOString().split("T")[0]}
                            ref={dateRef}
                            type="date"
                            disabled
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="form-outline d-flex flex-column">
                        <label
                          className="form-label mx-2"
                          htmlFor="department"
                          style={{ marginTop: "10px" }}
                        >
                          Time:
                        </label>

                        {!schedule ? (
                          <input
                            ref={timeRef}
                            type="time"
                            name="time"
                            id="timeInput"
                            required
                          />
                        ) : (
                          <input
                            ref={timeRef}
                            type="time"
                            name="time"
                            id="timeInput"
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="upload-btn-container">
                  {!schedule ? (
                    <button className="generate-btn" type="submit">
                      Schedule
                    </button>
                  ) : (
                    <div className="row">
                      <div className="col">
                        <button
                          onClick={(e) => {
                            handleDeleteSchedule(e);
                          }}
                          className="generate-btn"
                          type="submit"
                        >
                          Unschedule
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="table-responsive" ref={tableRef}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <div className="d-flex justify-content-center">
                  <input
                    type="checkbox"
                    checked={
                      unpagedFilteredUserList.length &&
                      selectedRows.length === unpagedFilteredUserList.length
                    }
                    onChange={selectAllRows}
                  />
                </div>
              </th>

              {fixedColumns.map((item, index) => (
                <th key={index} scope="col" className="align-middle">
                  <span title={String(item).toUpperCase()}>{item}</span>
                </th>
              ))}

              {dynamicTableColumns.map((item, index) => (
                <th key={index} scope="col" className="align-middle">
                  <span title={String(item).toUpperCase()}>{item}</span>
                </th>
              ))}

              <th className="action-button-header"></th>
              <th className="action-button-header"></th>
              <th className="action-button-header"></th>
            </tr>
          </thead>

          <tbody>
            {filteredUserList.length > 0 &&
              filteredUserList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex justify-content-center">
                      <input
                        type="checkbox"
                        checked={selectedRows.some(
                          (selectedItem) => selectedItem.id === item.id
                        )}
                        onChange={() => handleBodyCheckbox(item)}
                      />
                    </div>
                  </td>

                  {fixedColumns.map((column, index) => (
                    <td key={index}>
                      <span title={item[columnKeyMapping[column]]}>
                        {item[columnKeyMapping[column]]}
                      </span>
                    </td>
                  ))}

                  {dynamicTableColumns.map((column, index) => (
                    <td key={index}>
                      {dynamicTableColumns.includes(column) &&
                        (column === "Shift Timing" ? (
                          <span title={item[columnKeyMapping[column]]}>
                            {breakWordWithDash(item[columnKeyMapping[column]])}
                          </span>
                        ) : (
                          <span title={item[columnKeyMapping[column]]}>
                            {dynamicTableColumns.includes(column) &&
                              (column === "Email"
                                ? handleEmailWrap(
                                    item[columnKeyMapping[column]]
                                  )
                                : item[columnKeyMapping[column]])}
                          </span>
                        ))}
                    </td>
                  ))}

                  {isVisible && (
                    <td
                      className={`action-button-header ${
                        scheduledDeleteUsers.some(
                          (schedule) => schedule.userId === item.id
                        )
                          ? "scheduled"
                          : ""
                      }`}
                      onClick={() => {
                        setSchedulePopup(!schedulePopup);
                        setSelectedUserId(item.id);
                        getSchedule(item.id);
                      }}
                    >
                      <FaRegCalendarAlt  className="calender-icon"/>
                    </td>
                  )}

                  {isVisible && (
                    <td
                      className="action-button-header"
                      onClick={() => {
                        sessionStorage.setItem("user", JSON.stringify(item));
                      }}
                    >
                      <NavLink
                        to={"/admin/user-registration/single-user/update"}
                        style={{ fontSize: "0.9rem" }}
                      >
                        <FaEdit style={{ color: "#175572" }} />
                      </NavLink>
                    </td>
                  )}

                  <td
                    className="action-button-header"
                    onClick={() => {
                      setSelectedDetails(item);
                      setShowDetails(!showDetails);
                    }}
                  >
                    <FaUserAlt className="user-icon" style={{ color: "#175572" }} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {pageNumbers.length > 1 ? (
        <div className="pagination my-2">
          {currentPage !== 1 && totalPages > 5 && (
            <button onClick={handleDualPrevPage}>
              <FaAngleDoubleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {currentPage !== 1 && (
            <button onClick={handlePrevPage}>
              <FaAngleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {pageNumbers.map((page, index) => (
            <button
              key={index}
              disabled={currentPage === page}
              className={currentPage === page ? "active" : ""}
              onClick={() => {
                paginate(page, initialFilteredUserList);
                adjustPages(page);
              }}
            >
              {page}
            </button>
          ))}

          {currentPage !== totalPages && (
            <button onClick={handleNextPage}>
              <FaAngleRight className="arrow-icon" />
            </button>
          )}

          {currentPage !== totalPages && totalPages > 5 && (
            <button onClick={handleDualNextPage}>
              <FaAngleDoubleRight className="arrow-icon" />
            </button>
          )}
        </div>
      ) : (
        <></>
      )}

      {showDetails && (
        <UserRegistrationPopup
          toggleBtn={showDetails}
          toggleFunction={setShowDetails}
        >
          <div className="d-flex flex-column">
            <div className="d-flex">
              <p className="labels">First Name:</p>
              <p className="values">{selectedDetails.firstName}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Last Name:</p>
              <p className="values">{selectedDetails.lastName}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Email:</p>
              <p className="values">{selectedDetails.email}</p>
            </div>

            <div className="d-flex">
              <p className="labels">User Group:</p>
              <p className="values">{selectedDetails.role}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Department:</p>
              <p className="values">{selectedDetails.department}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Shift:</p>
              <p className="values">{selectedDetails.shiftTiming}</p>
            </div>

            <div className="d-flex">
              <p className="labels">Geography:</p>
              <p className="values">{selectedDetails.shift}</p>
            </div>
            <div className="d-flex">
              <p className="labels">Date of Birth:</p>
              <p className="values">{selectedDetails.dateOfBirth}</p>
            </div>
            <div className="d-flex">
              <p className="labels">Date of Joining:</p>
              <p className="values">{selectedDetails.dateOfJoining}</p>
            </div>
          </div>
        </UserRegistrationPopup>
      )}
    </div>
  );
}

export default ManageUser;
