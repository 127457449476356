import React from "react";

function ClanCard(props) {
  const { image, points, members, title, color } = props;

  return (
    <div className="clan-card-container" id="knights">
      <div className="card-img">
        <img src={image} alt="" />
      </div>
      <div className="card-text">
        <div className="header">{title}</div>
        <div className="subheader">Members: {members}</div>
        <div className="subheader">Points: {points}</div>
      </div>
    </div>
  );
}

export default ClanCard;
