import React from "react";

function UserRegistrationPopup(props) {
  const { toggleFunction, toggleBtn, children } = props;
  return (
    <div className="user-reg-popup-container">
      <div className="single-user-reg-popup">
        <div className="user-reg-popup-header">
          <span className="fs-6 fw-bold">Details</span>
          <button
            className="close-button"
            onClick={() => toggleFunction(!toggleBtn)}
          >
            &#10005;
          </button>
        </div>
        <hr style={{ borderTop: "2px dashed black" }} />
        <div className="user-reg-popup-content">
          <div className="form-fields-container">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default UserRegistrationPopup;
