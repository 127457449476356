import TokenService from "./token.service";
import axios from "../config/axios";


class MailerService {
  createMailer = async (formData) => {
    const response = await axios.post(`/mailer/create`, formData,{ 
    headers: {
      "Content-Type": "multipart/form-data",
    } 
  });
    console.log("Service", response);
    console.log('req , res')
    return response.data;
  };

  getMailers = async (type,date,searchText, page = 1) => {
    let url = `/mailer/get?sortBy=date:desc&mailType=${type}&limit=12&page=${page}`
    if(date){
      url += `&date=${date}`
    }
    if(searchText){
      url += `&searchText=${searchText}`
    }
    const response = await axios.get(url, {
      headers : {
        "Content-Type" : `Bearer ${TokenService.getLocalAccessToken()}`,
      }
    } );
    return response.data;

  };
  deleteMailer = async (id) => {
    const response = await axios.delete(`/mailer/${id}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

}

export default new MailerService();
