const DOMStatesReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, showSidebar: !state.showSidebar };
    case "TOGGLE_UPLOAD_FORM":
      return { ...state, showUploadForm: !state.showUploadForm };
    case "TOGGLE_MEMORY_IMAGE":
      return { ...state, showMemoryImage: !state.showMemoryImage };
    case "TOGGLE_MEMORY_UPLOAD_FORM":
      return { ...state, showMemoryUploadForm: !state.showMemoryUploadForm };
    case "TOGGLE_MEMORY_ALBUM_FORM":
      return { ...state, showMemoryAlbumForm: !state.showMemoryAlbumForm };
    case "TOGGLE_PROFILE_DROPDOWN":
      return { ...state, showProfileDropdown: !state.showProfileDropdown };
    case "TOGGLE_FEEDBACK_FORM":
      return { ...state, showFeedbackForm: !state.showFeedbackForm };
    case "TOGGLE_PROFILE_SIDEBAR":
      return { ...state, showProfileSidebar: !state.showProfileSidebar };
    case "SHOW_NOTIFICATION":
      return { ...state, showNotification: !state.showNotification };
    case "SHOW_NOTIFICATION_FORM":
      return { ...state, showNotificationForm: !state.showNotificationForm };
    default:
      return state;
  }
};

export default DOMStatesReducer;
