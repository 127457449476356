import TokenService from "./token.service";
import axios from "../config/axios";

class UserRegistrationService {
  createSingleUser = async (userData) => {
    const response = await axios.post(`/users`, userData);
    console.log("Service", response);
    return response.data;
  };

  getUsers = async (department, region, shiftTime) => {
    let url = `/users?limit=5000`;
    if (department) {
      url += `&department=${department}`;
    }
    if (region) {
      url += `&region=${region}`;
    }
    if (shiftTime) {
      url += `&shiftTiming=${shiftTime}`;
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

  getUsers1 = async (pageNo = 1) => {
    const response = await axios.get(`/users?limit=5000`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

  updateUser = async (userId, userData) => {
    const response = await axios.post(`/users/${userId}`, userData, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    sessionStorage.clear();
    return response.data;
  };

  forgotPassword = async (email) => {
    const response = await axios.post(`/users/forgot/${email}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response.data;
  };

  deleteUser = async (userId) => {
    const response = await axios.delete(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

  deleteMultipleUsers = async (userIds) => {
    const response = await axios.post(`/users/delete`, userIds, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  getUsersAccess = async () => {
    const response = await axios.get(`/users`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };
}

export default new UserRegistrationService();
