import { createSlice } from "@reduxjs/toolkit";

export const photoSharingSlice = createSlice({
  name: "photoSharing",
  initialState: {
    memories: [],
  },
  reducers: {
    setMemories: (state, action) => {
      
      state.memories = {
        [action.payload.key]: action.payload.result,
      };
    },
    addMemories: (state, action) => {
      state.memories = {
        ...state.memories,
        [action.payload.key]: action.payload.result,
      };
    },
  },
});

export const { setMemories, addMemories } = photoSharingSlice.actions;
export default photoSharingSlice.reducer;
