import React, { useRef } from "react";
import skilldata from "../QueryGenerateBox/SkillData";
import { useState } from "react";
import { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as FiIcons from "react-icons/fi";
import { Toast } from "bootstrap";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import { useSelector } from "react-redux";


const Option2 = ({ skill, final, setFinal, handleInputChange , handleSkillChange}) => {
  let skillsList = useSelector((state)=>state.skills.skillsList)

  // const [query, setQuery] = useState("");
  const [success, setSuccess] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");

  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showDelete, setShowDelete] = useState(-1);

  const skillRef = useRef()
  const [showPopUp, setShowPopup] = useState()
  const [showAddPopUp, setAddShowPopup] = useState(false)

  const handleSkillClick = (selectedSkill) => {
    const updatedQuery = final + `${selectedSkill}`;
    setFinal(updatedQuery);
  };
  const deleteSkill = (index) =>{
    let data = [...skillsList]
    data.splice(index, 1)
  
    handleSkillChange(data)
    setShowPopup()
  }
  const handleSkillSubmit = (skill) =>{
    
    if(!skill){
      setErrorNotific("Please enter the skill")
      setSuccessNotific("")

      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000);
      return
    }
   
    let data = skillsList.map(skill => skill.toLowerCase());
    let index = data.indexOf(skill.toLowerCase())
    
    if(index == -1){
      data.push(skill)
   
    handleSkillChange(data)
    setErrorNotific("")
setSuccessNotific("Skill added successfully")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000);
setAddShowPopup(false)

    } else {
setSuccessNotific("")
     
        setErrorNotific("Skill already exists")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000);
        return

      console.log("Skill already exists")
    }
   
  }
  const handleOperatorClick = (operator) => {
    setFinal(final + operator);
  };

  const handleCopy = async (e) => {
    setSuccessNotific("Copied Successfully!");
    setShowAlert(true);
  };
  // const handleInputChange = (event) => {
  //   setQuery(event.target.value);
  // };
  return (
    <div className="option1 pt-1">
       
      {showPopUp ? (
          <div className="popup-container" >
            <div className="popup">
              <div className="popup-header">
                <span className="fs-6 fw-bold">Delete Skill</span>
                <button className="close-button" onClick={()=>{setShowPopup()}}>
                  &#10005;
                </button>
              </div>
              
              <div className="popup-content mb-1">
                <h5>Are you sure you want to delete this skill from the database:"{skillsList[showPopUp]}"?</h5>
                <div className="d-flex justify-content-around my-1">
                <button color="primary" className="generate-btn"  type="button" onClick={()=>{deleteSkill(showPopUp);}}>Yes</button><button className="generate-btn" type="button" onClick={()=>setShowPopup()}>No</button>
              </div>
              </div>
          
            </div>
          </div>
        ) : null}
        {showAddPopUp ? (
          <div className="popup-container" >
            <div className="popup">
              <div className="popup-header">
                <span className="fs-6 fw-bold">Add skill</span>
                <button className="close-button" onClick={()=>{setAddShowPopup(false)}}>
                  &#10005;
                </button>
              </div>
              
              <div className="popup-content mb-1">
                <h5>Enter the skill you want to add to the main database:</h5>
                <input type="text" className="w-100 my-2" ref={skillRef} />
                <div className="d-flex justify-content-around my-1">
                <button color="primary" className="generate-btn"  type="button" onClick={()=>{handleSkillSubmit(skillRef.current.value);}}>Submit</button><button className="generate-btn"  type="button" onClick={()=>setAddShowPopup(false)}>Cancel</button>
              </div>
              </div>
          
            </div>
          </div>
        ) : null}
          <div className="toasts-container position-absolute mt-3 mx-3">
          <div className="toast fade hide" data-autohide="true" id="toast-searchQuery">
            <div className="toast-header">
              <i className="far fa-bell text-muted me-2"></i>
              <strong className="me-auto">ALOIS</strong>
              <button
                type="button"
                id="toastclose_btn"
                className="btn-close"
                data-bs-dismiss="toast"
              ></button>
            </div>
            <div className="toast-body">
              <p className="text-success">{success}</p>
              <p className="text-danger">{error}</p>
            </div>
          </div>
        </div>
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-between">
            <div>
              <label class="form-label">Skills</label>
            </div>
            {/* <div>
              <button className="skill-missing-btn"> ?</button>
            </div> */}
          </div>
          <div className="box d-flex">
            <div className="mx-auto d-flex flex-wrap align-items-center">
            {skillsList.map((i, index) => (
                <button
                onClick={() => handleSkillClick(i)}
          

                  draggable="true"
                  type="button"
                  className="button"
                  value={i}
                >
                  {i}
                <>   <AiFillCloseCircle style={{width:"20px"}} className="icon ms-2 cursor-pointer" onClick={()=>{setShowPopup(index)}} />  </>
                </button>
              ))}
         
              <BsPlusCircle type="button" className="fs-3" onClick={()=>{setAddShowPopup(true)}} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
        <div className="col">
          <label class="form-label">Operators</label>
          <div className="box d-flex">
            <div className="mx-auto d-flex">
              <div
                className="button"
                onClick={() => handleOperatorClick(" AND ")}
              >
                AND
              </div>
              <div className="button" onClick={() => handleOperatorClick(" OR ")}>
                OR
              </div>
              <div className="button" onClick={() => handleOperatorClick("(")}>
                (
              </div>
              <div className="button" onClick={() => handleOperatorClick(")")}>
                )
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="row d-flex">
     
     
    </div>




      <hr className="mx-auto " />

      <div className="row d-flex">
      <div className="col-xl-11">
     <textarea
        onChange={handleInputChange}
        className="text-center form-control w-75 mx-auto w-100"
        type="text"
        rows="2"
        value={final}
      >
        </textarea>
     </div>
     <div className="col-xl-1">
     <CopyToClipboard text={final}>
            <button type="button">
              <FiIcons.FiCopy
                onClick={handleCopy}
                className="recruit-icons"
              />
            </button>
          </CopyToClipboard>
     </div>
     <div className="col-xl-11 text-end  d-block me-5">
     {/* {skillCount?" No of characters: "+final.length:""} */}

      
     </div>
      </div>
    </div>
  );
};

export default Option2;
