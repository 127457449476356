import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../../assets/ALOIS_SOLUTIONS_LOGO.png";
import Footer from "../../components/Footer/Footer";
import userRegistrationService from "../../services/userRegistration.service";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [errorNotific, setErrorNotific] = useState("");
  const [successNotific, setSuccessNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [disabled, setDisabled] = useState(false)
  const emailRef = useRef();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (
      /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(emailRef.current.value)
    ) {
      let data = await userRegistrationService.getUsers1();

      let userData = data.results.filter(
        (user) => user.email == emailRef.current.value
      );

      if (!userData[0]) {
        setShowAlert(true);
        setSuccessNotific("");
        setErrorNotific(`User Not Found`);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }

      delete userData[0].role;
      delete userData[0].isEmailVerified;
      delete userData[0].id;

      try {
        await userRegistrationService.forgotPassword(emailRef.current.value);
      } catch (error) {
        console.error("Something went wrong.");
      }

      setErrorNotific("");
      setSuccessNotific(
        "Password updated successfully! Please check your email for latest password."
      );
      setShowAlert(true);
      setDisabled(true)
      setTimeout(() => {
        setShowAlert(false);
        navigate("/auth/login");
      }, 3000);
    } else {
      setErrorNotific("Email is not valid!");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <div className="login forgot-password-container">
      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>

            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            {errorNotific && <p className="text-danger">{errorNotific}</p>}
            {successNotific && <p className="text-success">{successNotific}</p>}
          </div>
        </div>
      )}

      {/* We don't have helmet in every page.
      So, commenting it and making title bar consistent with name: "ALOIS Assist Portal". */}
      {/* <Helmet>
        <title>Forgot Password</title>
      </Helmet> */}

      <div className="login-card d-flex">
        <div className="login-text col-7">
          <img
            alt="ALOISlogoblack12672"
            src={logo}
            width="125px"
            className="logo"
            onClick={() => (window.location = "/")}
          />

          <form onSubmit={handleForgotPassword}>
            <div className="fs-2 fw-bolder text-dark pt-5">
              <span className="mb-5">Change your password</span>
            </div>

            <div className="fs-1.125"></div>

            <input
              type="text"
              id="email"
              ref={emailRef}
              placeholder="Enter your email"
              className="form-control mt-5"
              required
            />

            <div className="d-flex flex-column">
            <button
              type="submit"
              disabled={disabled}
              className="button mt-4 px-2 py-1 fw-bolder text-white"
            >
              Forgot Password
            </button>
            <a className="w-75 text-center mt-2 me-2 pe-1" href="/auth/login">Login</a>
           
            </div>
          </form>

          {/* <div
            style={{
              position: "absolute",
              bottom: "2rem",
              width: "38%",
              margin: "0",
            }}
          >
            <Footer />
          </div> */}
        </div>

        <div>
          <img
            alt="unsplashLpbyDENbQQg248"
            src="../assets/output-onlinepngtools.png"
            className="login-element"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
