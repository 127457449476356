import store from "../redux/store";
import { refreshLogout } from "../features/userSlice";

class TokenService {
  getLocalRefreshToken() {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (tokens === null) {
      store.dispatch(refreshLogout());
      return;
    }

    return tokens.refresh.token;
  }

  getLocalAccessToken() {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (tokens === null) {
      store.dispatch(refreshLogout());
      return;
    } else {
      return tokens.access.token;
    }
  }

  updateLocalAccessToken(token) {
    const newToken = JSON.stringify(token);
    localStorage.setItem("tokens", newToken);
  }
}

export default new TokenService();
