import React from "react";
import { NavLink } from "react-router-dom";

function ErrorPage() {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ marginTop: "10rem" }}
    >
      <h1>404-Error Page</h1>
      <NavLink to={"/"}>
        <h3>Home</h3>
      </NavLink>
    </div>
  );
}

export default ErrorPage;
