import TokenService from "./token.service";
import axios from "../config/axios";
import alt_axios from "axios";

class QuizEvaluationService {
  createQuizEvalution = async (data) => {
    const response = await axios.post(`/quizEvaluation/create`, data);
    console.log("Service", response);
    return response.data;
  };
  updateQuizEvalution = async (id, data) => {
    const response = await axios.post(`/quizEvaluation/${id}`, data);
    console.log("Service", response);
    return response.data;
  };
  getEvaluations = async (id, booleanQueryId) => {
    const response = await axios.get(`/quizEvaluation/?limit=5000&testId=${id}&booleanStringId=${booleanQueryId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });
    return response.data;
  };

}

export default new QuizEvaluationService();