import React, { useEffect, useRef, useState } from "react";
import { BsChatSquareDotsFill } from "react-icons/bs";
import { IoIosPaperPlane } from "react-icons/io";
import TokenService from "../../services/token.service";

function ChatBot() {
  const [showChatbotPanel, setShowChatbotPanel] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Handbook"); // Set default option to "Handbook"
  const [typing, setTyping] = useState(false);
  const [question, setQuestion] = useState("");
  const [ans, setAns] = useState([]);

  const messageEndRef = useRef(null);
  const chatbotInputRef = useRef(null);

  const handleShowChatbot = () => {
    setShowChatbotPanel(!showChatbotPanel);
  };

  const queryAdd = () => {
    if (question.trim() !== "" && typing === false) {
      handleAdd();
    }
  };

  const pressEnter = (e) => {
    if (e.key === "Enter" && typing === false) {
      handleAdd();
    }
  };

  const scrollToBottom = () => {
    if (messageEndRef.current && messageEndRef.current.lastElementChild) {
      messageEndRef.current.lastElementChild.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleAdd = async () => {
    setAns((preAns) => [...preAns, { responsed: question, role: "User" }]);
    setQuestion("");
    setTyping(true);

    const formdata = new FormData();
    formdata.append(
      "data",
      `{"Question":"${question}", "QuestionType":"${selectedOption}"}`
    );

    const url = `https://${process.env.REACT_APP_API_URL}/chatbot/chat`;
    const options = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    };

    await fetch(url, options)
      .then((response) => response.json())
      .then((response) => {
        setAns((preAns) => [
          ...preAns,
          {
            responsed: response.value,
            role: "Assistant",
          },
        ]);
      })
      .catch((err) => console.error(err));

    setTyping(false);
    scrollToBottom();
  };

  useEffect(() => {
    if (messageEndRef && messageEndRef.current) {
      scrollToBottom();
    }

    if (selectedOption && chatbotInputRef && chatbotInputRef.current) {
      chatbotInputRef.current.focus();
    }
  });

  return (
    <div className="chatbot-container">
      {showChatbotPanel && (
        <div className="chatbot-outer p-1">
          <div className="overflow-auto" ref={messageEndRef}>
            {ans.length > 0 &&
              ans.map((ans, index) => {
                return (
                  <div className="d-flex" key={index}>
                    <div
                      className={
                        ans.role === "User" ? "ms-auto me-1 ps-5" : "ms-1 w-75"
                      }
                    >
                      <p
                        className="p-1 text-black"
                        style={
                          ans.role === "User"
                            ? {
                                borderRadius: "10px",
                                border: "1px solid #B5D3D1",
                              }
                            : {
                                background: "#DAEFF4",
                                borderRadius: "10px",
                                border: "1px solid #B5D3D1",
                              }
                        }
                      >
                        {ans.responsed}
                      </p>
                    </div>
                  </div>
                );
              })}

            {typing && (
              <div className="ms-1 w-25 rounded p-1 text-black my-2">
                <div className="typing-loader"></div>
              </div>
            )}
          </div>

          {selectedOption && (
            <div>
              <input
                type="text"
                value={question}
                className="chatbot-input"
                placeholder="Type here"
                onChange={(e) => setQuestion(e.target.value)}
                onKeyDown={pressEnter}
                ref={chatbotInputRef}
              />
              <button onClick={queryAdd} className="send-chat-btn">
                <IoIosPaperPlane className="icon" />
              </button>
            </div>
          )}

          <div className="nav nav-pills nav-fill pt-1">
            <label
              className={`nav-item me-1 ${
                selectedOption === "Handbook" && "active"
              }`}
            >
              <input
                type="radio"
                className="nav-link"
                name="options"
                value="Handbook"
                checked={selectedOption === "Handbook"}
                onChange={() => setSelectedOption("Handbook")}
              />
              Handbook
            </label>

            <label
              className={`nav-item ${selectedOption === "uKnowva" && "active"}`}
            >
              <input
                type="radio"
                className="nav-link"
                name="options"
                value="uKnowva"
                checked={selectedOption === "uKnowva"}
                onChange={() => setSelectedOption("uKnowva")}
              />
              uKnowva
            </label>
          </div>
        </div>
      )}

      <div
        className="chatbot-btn"
        onClick={handleShowChatbot}
        role="button"
        style={
          showChatbotPanel
            ? { background: "#fff", border: "1px solid #26c6ea" }
            : {}
        }
      >
        <BsChatSquareDotsFill
          className="icon"
          style={showChatbotPanel ? { fill: "#26c6ea" } : {}}
        />
      </div>
    </div>
  );
}

export default ChatBot;
