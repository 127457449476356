import React from "react";

function GradientBorderDropDown(props) {
  const { text, icon, changeHandler, additionalClass, options } = props;
  return (
    <div
      className={`upload-btn mt-1 ${
        additionalClass === undefined ? "" : additionalClass
      }`}
    
    >
      <div className="outer-div" style={{width:"fit-content"}}>
        <div className="inner-div pt-1 justify-content-between" style={{width:"fit-content"}}>
        <select   onChange={(e) => changeHandler(e.target.value)} className="gradient-dropdown form-select">
        {options.map(
                  (item) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ),
                  []
                )}
        </select>
          {/* {icon}{ icon ? <span style={{width:"45px"}}></span>:<></> }{text} */}
        </div>
      </div>
    </div>
  );
}

export default GradientBorderDropDown;
