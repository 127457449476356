import React, { useState, useEffect, useRef } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { ImDownload3 } from "react-icons/im";
import { IoMdTrash } from "react-icons/io";
import { GrFormClose } from "react-icons/gr";
import dashboardService from "../../services/dashboard.service";
import { AiOutlineClose } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import ImageUtil from "../../utilities/ImageUtil";
import licenceValidator from "../../permission/validate";

const ImageCarousel = () => {
  const [showUpload, setShowUpload] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageList, setImageList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [userType, setUserType] = useState("user");
  const [date, setDate] = useState(new Date().toLocaleDateString("en-CA"));
  const fileRef = useRef(null);
  const [isvisible, setIsVisible] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  const [selectedBulletinId, setSelectedBulletinId] = useState("");
  const [maxDate, setMaxDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );

  const getBulletinBoard = async (givenDate = "") => {
    let response = "";
    try {
      if (givenDate === "")
        response = await dashboardService.getBulletinImages(date);
      else response = await dashboardService.getBulletinImages(givenDate);
      setImageList(response);
    } catch (error) {
      console.log("Something went wrong.");
    }
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "home");
    setIsVisible(validator.isReadable("bulletin"));
    setIsDeletable(validator.isDelete("bulletin"));
    // set max date limit according to permissions
    if (validator.isReadable("bulletin")) {
      let currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() + 1);
      setMaxDate(currentDate.toLocaleDateString("en-CA"));
    } else {
      setMaxDate(new Date().toLocaleDateString("en-CA"));
    }
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const files = fileRef.current.files;
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append("filePath", files[i]);
        formData.append("postScheduleDate", date);
        const result = await dashboardService.uploadBulletinImage(formData);
      }

      setShowUpload(false);
      setErrorNotific("");
      setSuccessNotific("Images Uploaded");
      getBulletinBoard();
    } catch (error) {
      setErrorNotific("Image Upload Failed");
      setSuccessNotific("");
    }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this item?"
      );

      if (shouldDelete) {
        const result = await dashboardService.deleteBulletinImage(
          selectedBulletinId
        );
        setSelectedBulletinId("");
        setSelectedImage("");
        getBulletinBoard();
      }
    } catch (error) {
      console.log("Something went wrong.");
    }
  };

  const handleDownload = async () => {
    // const transformedLink = ImageUtil.transform(selectedImage);
    const response = await fetch(selectedImage);
    const tempArray = selectedImage.split("/");
    const fileName = tempArray[tempArray.length - 1];
    response.arrayBuffer().then(function (buffer) {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    getBulletinBoard(e.target.value);
  };

  useEffect(() => {
    getBulletinBoard();
    let user = JSON.parse(localStorage.user).role;
    setUserType(user);
  }, []);

  return (
    <div className="dashboard-upper-container">
      <div className="bulletin-board">
        <div className="d-flex justify-content-center">
          <span className="header fw-bold">Bulletin Board</span>

          <div className="bulletin-date-div">
            <input
              type="date"
              name="bulletin-date"
              id="bulletin-date"
              className="bulletin-date mx-2"
              value={date}
              onChange={handleDateChange}
              max={maxDate}
            />
            {isvisible && (
              <button
                className="upload-btn"
                onClick={() => setShowUpload(!showUpload)}
              >
                <FiUpload />
              </button>
            )}
          </div>
        </div>
      </div>

      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>
          <hr />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      )}

      <div className="image-carousel">
        <div
          id="carouselExampleInterval"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          {imageList.length > 1 && (
            <button
              className="icon-button"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <BsFillArrowLeftCircleFill className="icon carousel-control-prev-icon" />
              <span className="visually-hidden">Previous</span>
            </button>
          )}

          {imageList.length > 0 ? (
            <div className="carousel-inner carousel-images">
              {imageList.map((image, index) => {
                return (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    data-bs-interval="3000"
                  >
                    <img
                      key={index}
                      src={image.filePath}
                      alt="bulletins"
                      onClick={() => {
                        setSelectedImage(image.filePath);
                        setSelectedBulletinId(image.id);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <img
              src="/assets/alois-welcome-img.png"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}

          {imageList.length > 1 && (
            <button
              className="icon-button"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <BsFillArrowRightCircleFill className="icon carousel-control-next-icon" />
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div>

      {showUpload && (
        <div className="uploadform-popup">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">Upload New Images</span>
              <button
                className="close-button"
                onClick={() => setShowUpload(!showUpload)}
              >
                &#10005;
              </button>
            </div>
            <hr style={{ borderTop: "3px dotted black" }} />
            <div className="popup-content">
              <form onSubmit={handleUpload} encType="multipart/form-data">
                <div className="form-fields-container">
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="chooseImage">
                          Choose Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          accept=".png,.jpeg,.jpg"
                          multiple
                          ref={fileRef}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="date">
                          Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          id="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload-btn-container mt-2">
                  <button className="generate-btn" type="submit">
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {selectedImage.length ? (
        <div className="bulletin-image-view">
          <div className="popup-navbar">
            <ImDownload3 className="icon mx-2" onClick={handleDownload} />
            {isDeletable ? (
              <IoMdTrash className="icon mx-2" onClick={handleDelete} />
            ) : (
              <></>
            )}
            <div className="close-btn mx-3">
              <GrFormClose
                className="icon"
                style={{ fill: "rgb(23, 85, 114)" }}
                onClick={() => setSelectedImage("")}
              />
            </div>
          </div>
          <img src={selectedImage} alt="Image" />
        </div>
      ) : null}
    </div>
  );
};

export default ImageCarousel;
