import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaBirthdayCake,
  FaMinus,
  FaPlus,
  FaSearch
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import { FiDownload } from "react-icons/fi";
import birthdayTemplateBankService from "../../services/birthdayTemplateBank.service";
import minioService from "../../services/minio.service";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import { Spinner } from "reactstrap";

function BirthdayPostGenerator() {
  const visiblePages = 5;
  const postsPerPage = 10;
  const [birthdayPosts, setBirthdayPosts] = useState([]);
  const [filteredPostList, setFilteredPostList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("")
  const [initialFilteredPostList, setInitialFilteredPostList] = useState([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomPopup, setZoomPopup] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0)
  const [loading, setLoading] = useState(false);
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null)
  const userRef = useRef(null);

  const tableRef = useRef(null);
  const statusRef = useRef(null);
  const nodeEnv = process.env.REACT_APP_NODE_ENV;
  const [zoomLoading, setZoomLoading] = useState(false)
  let user = localStorage.getItem("user");
  user = JSON.parse(user);


  useEffect(()=>{
    let dashboardElement = document.querySelector(".dashboard");
    
    const body = document.body;

    if (showPreviewPopup && dashboardElement) {
      
      // Remove the 'transform' property
      
      
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if(!showPreviewPopup && dashboardElement){
      

    }
  },[showPreviewPopup])
  useEffect(()=>{
    let dashboardElement = document.querySelector(".dashboard");
    
    const body = document.body;

    if (showPreviewPopup && dashboardElement) {
      
      // Remove the 'transform' property
      
      
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if(!showPreviewPopup && dashboardElement){
      

    }
  },[showPreviewPopup])
  const getBirthdayPosts = async (pageNumber) => {
    const data = await birthdayTemplateBankService.getBirthdayPosts(1);

    const posts = data.data.results
    
    const compareDOB = (person1, person2) => {
      // Convert date of birth strings to Date objects
      const dob1 = new Date(person1.dateOfBirth);
      const dob2 = new Date(person2.dateOfBirth);
    
      // Compare the months first
      const monthDiff = dob2.getMonth() - dob1.getMonth();
    
      if (monthDiff !== 0) {
        return monthDiff;
      }
    
      // If the months are the same, compare the days
      return dob2.getDate() - dob1.getDate();
    };
  
  // Sort the array by date of birth (oldest to youngest)
  posts.sort(compareDOB);
    setBirthdayPosts(posts);
    
    paginate(pageNumber || 1, posts);
    adjustPages(pageNumber || 1, Math.ceil(posts.length / postsPerPage));
    setInitialFilteredPostList(posts);

    const tempTotalPages = Math.max(1, Math.ceil(posts.length / postsPerPage));
    setTotalPages(tempTotalPages);
  };

  useEffect(() => {
    getBirthdayPosts();
  }, []);

  const adjustPages = (currentPage, tempTotalPages) => {
    const finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    } else {
      tempStart = 1;
      tempEnd = finalTotalPages;
    }

    const tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    setCurrentPage(currentPage);
  };

  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    paginate(setPageNumber, initialFilteredPostList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);
    paginate(setPageNumber, initialFilteredPostList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    paginate(setPageNumber, initialFilteredPostList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);
    paginate(setPageNumber, initialFilteredPostList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const paginate = (currPage, data = []) => {
    // Scroll to the top of the table whenever page number is changed
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }

    const start = (currPage - 1) * postsPerPage;
    const end = start + postsPerPage;

    if (data.length > 0) {
      setFilteredPostList(data.slice(start, end));
    } else {
      (!statusRef.current.value && !selectedDate && !userRef.current.value) &&
        setFilteredPostList(birthdayPosts.slice(start, end));
    }
  };
  const filterBirthdays = () => {
    let updatedPostLists = birthdayPosts;
  
    if (userRef.current.value) {
      updatedPostLists = updatedPostLists.filter(
        (item) => item.name.toLowerCase().includes(userRef.current.value.toLowerCase())
      );
    }
  
    if (statusRef.current.value) {
      updatedPostLists = updatedPostLists.filter(
        (item) => item.status == statusRef.current.value
      );
    }
  
    if (selectedDate) {
      updatedPostLists = updatedPostLists.filter(
        (item) => {
          const itemDate = new Date(item.dateOfBirth);
          let dateData = (
            itemDate.getDate() === selectedDate.getDate() &&
            itemDate.getMonth() === selectedDate.getMonth()
          )
          console.log(itemDate.getDate(), "===", selectedDate.getDate() ,"&&",
          itemDate.getMonth(), "===", selectedDate.getMonth())
          return dateData;
        }
      );
    }
  
    setFilteredPostList(updatedPostLists);
    setInitialFilteredPostList(updatedPostLists);
  
    const tempTotalPages = Math.ceil(updatedPostLists.length / postsPerPage);
    setTotalPages(tempTotalPages);
    adjustPages(1, tempTotalPages);
    paginate(1, updatedPostLists);
    setCurrentPage(1);
  };
  
  // JSX for the DatePicker component

 
useEffect(()=>{
  filterBirthdays()
},[selectedDate])
  const handleView = (id) => {
    setShowPreviewPopup(true);

    const setPost = birthdayPosts.filter((item) => item.id == id);
    setSelectedPost(setPost[0]);
  };

  const generatePPT = async (id) => {
    try {
      setShowAlert(false);

      const response = await birthdayTemplateBankService.getPPT([id]);
      const pptLink = response?.data?.results?.[0]?.filePath;

      if (response?.data?.results.length) {
        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = pptLink;
        link.download = "birthday_templates.ppt";
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Remove the temporary anchor element
        document.body.removeChild(link);
      } else {
        setErrorNotific("There is no PPT to download.");
        setSuccessNotific("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      setErrorNotific("PPT Download Failed.");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const handleSaveZoomedImage = async () =>{
    await birthdayTemplateBankService.updateBirthdayPost(
      { filePath: currentUrl, status: "pending", zoom:zoomLevel },
      selectedPost.id
    );
    let post = selectedPost
    post.filePath = currentUrl
    setSelectedPost(post)
    await getBirthdayPosts();
    setZoomPopup(false)
    setZoomLevel(false)
    setCurrentUrl("")
    setShowPreviewPopup(true)
    setErrorNotific("");
    setSuccessNotific("User image zoomed sucessfully!");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }
  const downloadUserImage = async (empId) => {
try {
  
// Fetch the image
  let response = await birthdayTemplateBankService.getUserImage(empId)

const imageDataUint8Array = new Uint8Array(response.data.data.data);
const binary = imageDataUint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
const base64String = btoa(binary);
const imageUrl = `data:image/png;base64,${base64String}`;
console.log(imageUrl)

    // Create a link element
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `${empId}.jpg`; // Set the filename for download
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup: remove the temporary URL and link element
    link.remove();
    window.URL.revokeObjectURL(imageUrl);
  }
  catch (error) {
    
    setErrorNotific("User image Download Failed.");
    setSuccessNotific("");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
   
  }
  const handleUpload = (id) => {
    try {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async function () {
        const imageFile = input.files[0];

        if (!imageFile) {
          return;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
          setErrorNotific("Image must be of type jpg or png!");
          setSuccessNotific("");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
          return;
        }

        const response = await minioService.UploadSingleFile(
          imageFile,
          "birthday_post"
        );

        await birthdayTemplateBankService.updateBirthdayPost(
          { filePath: response.data.url, status: "pending" },
          id
        );

        await getBirthdayPosts();

        setErrorNotific("");
        setSuccessNotific("Birthday post has been successfully updated!");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      };
    } catch (err) {
      setErrorNotific("Something went wrong!");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleApprove = async (postId) => {
    await birthdayTemplateBankService.updateBirthdayPost(
      {
        userId: user.id,
        userName: user.firstName + " " + user.lastName,
        status: "Approved",
      },
      postId
    );

    getBirthdayPosts(currentPage);

    setErrorNotific("");
    setSuccessNotific("Birthday post has been approved!");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    setShowPreviewPopup(false);
  };

  const handleReject = async (postId) => {
    await birthdayTemplateBankService.updateBirthdayPost(
      {
        userId: user.id,
        userName: user.firstName + " " + user.lastName,
        status: "Rejected",
      },
      postId
    );

    getBirthdayPosts(currentPage);

    setErrorNotific("");
    setSuccessNotific("Birthday post has been rejected!");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    setShowPreviewPopup(false);
  };

  const handleDownload = async (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "birthday_post";
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const regenerateBirthdayPost = async () => {
    setLoading(true)
    const response = await birthdayTemplateBankService.createBirthdayPost()
    setSuccessNotific("Birthday post regenerated successfully.");
    getBirthdayPosts(1);

    setLoading(false)
    setErrorNotific("");
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }
  const formatDate = (inputDate, includeTime) => {
    if (inputDate) {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      let formattedDate = `${day}/${month}/${year}`;

      if (includeTime) {
        let hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 should be displayed as 12
        const minutes = String(date.getMinutes()).padStart(2, "0");
        formattedDate += `, ${hours}:${minutes} ${ampm}`;
      }

      return formattedDate;
    } else {
      return "-";
    }
  };
  const handleZoomIn = async () => {
    setZoomLoading(true)
    setZoomLevel(zoomLevel + 20)
    let response = await birthdayTemplateBankService.zoomUserImage({
      zoom:zoomLevel + 20
    },selectedPost.id)
    setZoomLoading(false)
    setCurrentUrl(response.data.url)

  }
  const handleZoomOut = async () => {
    setZoomLevel(zoomLevel - 20)
    setZoomLoading(true)

    let response = await birthdayTemplateBankService.zoomUserImage({
      zoom:zoomLevel - 20
    },selectedPost.id)
    setCurrentUrl(response.data.url)
    setZoomLoading(false)

    
  }
  return (
    <div className="birthday-post">
        <div className="indicator-container">
    <div className="d-flex  text align-items-center gap-2">
          <FaBirthdayCake className="icon" />
         Birthday Post Generator</div>
    
         <div className="d-flex align-items-center gap-3">
              {nodeEnv=="dev" && <GradientBorderButton
                text="Regenerate"
                clickHandler={regenerateBirthdayPost}
                additionalClass="z-i-0 disabled"
                iconAdditionalClass="icon-width"
                innerDivClass="inner-div-styles"
              />}
           {loading ?   <div className="mt-1">
              <Spinner style={{width:"20px", height:"20px"}} animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div> : <></>}
             
              </div>
        </div>
    <div className="birthday-post-outer">
  
        <div className="birthday-post-inner">
        <div className="upper-section ">
        <div className="d-flex justify-content-between gap-3 m-3">
          <div className="d-flex me-2 gap-3">
          <div className="d-flex">
          <div className="input-group mb-3">
            <button
              className={`btn btn-outline-secondary search-btn
               ${  searchInputFocused && "search-input-focused"}`}
              type="button"
              id="button-addon1"
              onClick={() => userRef.current.focus()}
            >
              <FaSearch className="mb-1" />
            </button>

            <input
              type="text"
              className="form-control search-input-user"
              placeholder="Search"
              aria-label="Example text with button addon"
              aria-describedby="button-addon1"
              ref={userRef}
              onChange={() => filterBirthdays()}
              onFocus={() => setSearchInputFocused(true)}
              onBlur={() => setSearchInputFocused(false)}
            />
            
          </div>

        </div>
        <div className="d-flex">
          <div className="input-group mb-3">
           

          <DatePicker
                          type="date"
                          id="dateInput"
                          selected={selectedDate}
                          dateFormat="dd/MM"
                          onChange={(e) => {
                            setSelectedDate(e);
                         
                          }}
                          style={{ height: "60px" }}
                          placeholderText="Select a start date"
                        />
            
          </div>
          
        </div>
            <div className="form-outline">
              <select
                id="department"
                className="form-select dropdown-styles"
                ref={statusRef}
                onChange={filterBirthdays}
              >
                <option value="" className="p-5">
                  All
                </option>

                <option value="approved" className="p-5">
                  Approved
                </option>

                <option value="pending" className="p-5">
                  Pending
                </option>

                <option value="rejected" className="p-5">
                  Rejected
                </option>

                <option value="expired" className="p-5">
                  Expired
                </option>
              </select>
              <div className="arrow-down"></div>
            </div>
          
          </div>

          <div className="d-flex me-2 dotted-section">
            <label>Note:</label>

            <p className="ms-1">
              The Generate Button will download a PPT file for you to recreate
              or edit post on your device. And Upload button help you to upload
              recreated post.
            </p>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table" ref={tableRef}>
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Department</th>
              <th scope="col">Shift Timing</th>
              <th scope="col">Date of Birth</th>
              <th scope="col">View</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {filteredPostList.map((item) => {
              let status = capitalizeFirstLetter(item.status);
              return (
                <tr className="w-100" key="1">
                  <td className="text-center">{item.name}</td>

                  <td className="text-center">{item.department}</td>

                  <td className="text-center">{item.shiftTiming}</td>

                  <td className="text-center">
                    {formatDate(item.dateOfBirth, false)}
                  </td>

                  <td className="text-center">
                    <button
                      className="view-button"
                      onClick={() => {
                        handleView(item.id);
                      }}
                    >
                      View
                    </button>
                  </td>

                  <td
                    className="text-center"
                    style={
                      item.status == "pending"
                        ? { color: "#175572", fontWeight: "600" }
                        : item.status == "rejected"
                        ? { color: "#EA2626", fontWeight: "600" }
                        : item.status == "approved"
                        ? { color: "#51C677", fontWeight: "600" }
                        : { color: "darkgrey", fontWeight: "600" }
                    }
                  >
                    {status}
                  </td>

                  <td className="text-center">
                    {item.status == "rejected" && (
                      <GradientBorderButton
                        text="Download"
                        icon={<FiDownload />}
                        clickHandler={() => {
                          generatePPT(item.templateId);downloadUserImage(item.empId)
                        }}
                        additionalClass="mt-0"
                        outerDivClass="height-34"
                        innerDivClass="height-30  "
                        iconAdditionalClass="icon-width"
                      />
                    )}
                  </td>

                  <td className="text-center">
                    {item.status == "rejected" && (
                      <GradientBorderButton
                        text="Upload"
                        clickHandler={() => {
                          handleUpload(item.id);
                        }}
                        additionalClass="mt-0"
                        outerDivClass="height-34"
                        innerDivClass="height-30"
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {pageNumbers.length > 1 && (
        <div className="pagination">
          {currentPage !== 1 && totalPages > 5 && (
            <button onClick={handleDualPrevPage}>
              <FaAngleDoubleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {currentPage !== 1 && (
            <button onClick={handlePrevPage}>
              <FaAngleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {pageNumbers.map((page, index) => (
            <button
              key={index}
              disabled={currentPage === page}
              className={currentPage === page ? "active" : ""}
              onClick={() => {
                paginate(page, initialFilteredPostList);
                adjustPages(page);
              }}
            >
              {page}
            </button>
          ))}

          {currentPage !== totalPages && (
            <button onClick={handleNextPage}>
              <FaAngleRight className="arrow-icon" />
            </button>
          )}

          {currentPage !== totalPages && totalPages > 5 && (
            <button onClick={handleDualNextPage}>
              <FaAngleDoubleRight className="arrow-icon" />
            </button>
          )}
        </div>
      )}
{zoomPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Zoom User Image</div>

              <div>
                <button
                  className="close-button"
                  onClick={() => setZoomPopup(false)}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons">
              <img
                src={currentUrl}
                alt={`happy_birthday_${selectedPost.name}`}
                className="preview-image"
              />

              <div className="status-btns gap-3">
           {zoomLoading ?   <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />:<></>}
              <GradientBorderButton
                  icon={<FaPlus />}
                  clickHandler={() => {
                    handleZoomIn();
                  }}
                  innerDivClass="zoom-btn"
                />
               
                 <GradientBorderButton
                  icon={<FaMinus />}
                  clickHandler={() => {
                    handleZoomOut();
                  }}
                   innerDivClass="zoom-btn"

                />
               {zoomLevel != 0 && !zoomLoading ?  <GradientBorderButton
                  text="Save"
                  clickHandler={() => {
                  handleSaveZoomedImage()
                  }}
                   innerDivClass="zoom-btn"

                />:<></>}
              </div>
            </div>
          </div>
        </div>
      )}
      {showPreviewPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Template Preview</div>

              <div>
                <button
                  className="close-button"
                  onClick={() => setShowPreviewPopup(false)}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons">
              <img
                src={selectedPost.filePath}
                alt={`happy_birthday_${selectedPost.name}`}
                className="preview-image"
              />

              <div className="status-btns gap-3">
              <GradientBorderButton
                  clickHandler={() => {
                    setZoomPopup(true);setShowPreviewPopup(false);setCurrentUrl(selectedPost.filePath);setZoomLevel(selectedPost.zoom ? selectedPost.zoom: 0)
                  }}
                  text="Zoom User Image"
                  innerDivClass="zoom-btn"
                />
               
                <GradientBorderButton
                  text="Download"
                  icon={<FiDownload />}
                  clickHandler={() => {
                    handleDownload(selectedPost.filePath);
                  }}
                  iconAdditionalClass="icon-width"
                />
                <GradientBorderButton
                  text="Approve"
                  clickHandler={() => {
                    handleApprove(selectedPost.id);
                  }}
                />

                <GradientBorderButton
                  text="Reject"
                  clickHandler={() => {
                    handleReject(selectedPost.id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>

            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section">
            {errorNotific && <p className="text-danger">{errorNotific}</p>}
            {successNotific && <p className="text-success">{successNotific}</p>}
          </div>
        </div>
      )}
        </div>
    </div>

    </div>
  );
}

export default BirthdayPostGenerator;
