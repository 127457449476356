const skilldata = [
    {
        title: 'Skills 1'
    },
    {
        title: 'Skills 2'
    },
    {
        title: 'Skills 3'
    }
];

export default skilldata;