import React from "react";
import AdminClanGraph from "./AdminClanGraph";

function AdminDashboard() {
  return (
    <div className="dashboard-container px-1" style={{ marginTop: "7.5rem" }}>
      <label
        className="form-label"
        htmlFor="department"
        style={{ marginTop: "10px" }}
      >
        Assist Users
      </label>
      <div className="row">
        <div className="col-6">
          <div className="row " style={{marginLeft : "10px"}}>

<div className="user-card col-5"
id="users">
  <div className="user-text">
    <div className="header">
    LIVE COUNTS
    </div>
    <div className="subheader">
    Total Users
    </div>
  </div>

</div>
<div className="user-card col-5"
id="live"
style={{marginLeft : "7px"}}>
  <div className="user-text">
    <div className="header">
    LIVE COUNTS
    </div>
    <div className="subheader">
    Total Users
    </div>
  </div>

</div>
          </div>
<div className="buzz-card">
  div

</div>
        </div>
        <div className="col-6">
          <AdminClanGraph />
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
