import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import "turn.js";
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import "./viewer.css";
import { pdfjs } from "react-pdf";

import workerSrc from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
class Turn extends React.Component {
  static defaultProps = {
    style: {},
    className: "",
    options: {}
  };

  componentDidMount() {
    if (this.el) {
      $(this.el).turn(Object.assign({}, this.props.options));
    }
      
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    if (this.el) {
      $(this.el)
        .turn("destroy")
        .remove();
    }
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }
  componentDidUpdate(prevProps) {
    
    // Check if the 'previous' prop has changed
    if (this.props.previous !== prevProps.previous) {
      // Perform actions you want when 'previous' prop changes
      // if(this.props.previous){
      $(this.el).turn("previous");

      // }
    }
    if (this.props.next !== prevProps.next) {
      // Perform actions you want when 'previous' prop changes
      // if(this.props.previous){
      $(this.el).turn("next");

      // }
    }
  }
  handleKeyDown = event => {
    if (event.keyCode === 37) {
      if(this.props.pageNumber > 1){
      $(this.el).turn("previous");
      }
    }
    if (event.keyCode === 39) {
      if(this.props.pageNumber < this.props.totalPages){
        $(this.el).turn("next");

      }
    }
  };

  render() {
    return (
      <div
        className={this.props.className}
        style={Object.assign({}, this.props.style)}
        ref={el => (this.el = el)}
      >
        {this.props.children}
      </div>
    );
  }
}

const options = {
  display: "double",
  duration:1000,
  // acceleration: true,
  elevation: 0,
  when: {
    turned: function(e, page) {
      
      console.log("Current view: ", $(this).turn("view"));
    }
  }
};



const PdfViewer = ({file, previous, next, setPageNumber, height, width}) => {
  const [zoomedIn, setZoomedIn] = useState(false)
  const [imageUrls, setImageUrls] = useState([])
  const [zoomArea, setZoomArea] = useState({ x: 0, y: 0 }); // Coordinates of zoom area
  const zoomLevel = 2; // Example zoom level
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    const handleWheel = (event) => {
      const target = event.target;

      // Check if the target is not the specified div
      if (!event.target.classList.contains('magazine') && event.ctrlKey) {
        event.preventDefault();
        // event.stopPropagation();
      } else {

      }
    };

    const handleEsc = (event) => {
      
      if (event.keyCode === 27) {
        
        document.body.removeEventListener('wheel', handleWheel);
     
        // Handle ESC key press here
        
      }
    };

    // Add event listener when component mounts
    document.addEventListener('keydown', handleEsc);
    // Add event listener to prevent mouse wheel on every element except the specified div
    document.body.addEventListener('wheel', handleWheel, { passive: false });

    // Clean up the event listener
    return () => {
      document.removeEventListener('keydown', handleEsc);

      document.body.removeEventListener('wheel', handleWheel);
    };
  }, []);
 
  // Function to handle zoom into a specific area
  const zoomIntoArea = (x, y) => {
    // Calculate the required transformation parameters
    const scale = zoomLevel;
    const translateX = -x * (zoomLevel - 1);
    const translateY = -y * (zoomLevel - 1);

    // Update the state with the new transformation parameters
    setZoomArea({ x, y });
    if (transformWrapperRef.current) {
      const transformWrapper = transformWrapperRef.current;

      // Set the initial transformation parameters after the component has mounted
      transformWrapper.setTransform({
        scale: 2, // Initial zoom level
        positionX: x, // Initial X position
        positionY: y, // Initial Y position
      });
    }
    // You might need to use the setTransform method provided by the library to update the transformation
  };
  const transformWrapperRef = useRef(null);
  const handlePageTurn = ( page) => {
    setPageNumber(page);
    setCurrentPage(page)
  };
  let page = document.querySelector(".magazine")
  const handleWheel = (e) => {
    
    if(e?.state?.scale > 1){
      setZoomedIn(true)
    } else {
      setZoomedIn(false)

    }
    // Get the current scale when the mouse wheel is used for zooming

  };
const handleChange = (newState) => {
    // Get the current scale when the transformation state changes
    const currentScale = newState.scale;
    console.log("Current Scale:", currentScale);
  };
  const getCurrentScale = (e) => {
    if (transformWrapperRef.current) {
      
      const { scale } = transformWrapperRef.current.getTransform();
      return scale;
    }
    return null;
  };
useEffect(()=>{

  if(page){
    page?.addEventListener('wheel', function(e) {
      e.preventDefault();
    // e.stopPropagation();
    return false
  });
  }
  
},[page])
  return (

    <Turn pageNumber={currentPage} totalPages={file?.length}  onPageTurn={handlePageTurn}  options={{...options,height, width,when: {
            turned: function (e, page) {
              
              handlePageTurn(page);
            }
          }}} previous={previous} next={next} className="magazine m-0" >
      {file?.map((page, index) => (
          <TransformWrapper  options={{
            limitToBounds: false, // Allow zooming out beyond the original size of the image
            minScale: 1, // Minimum scale allowed (zoomed out)
            maxScale: 10, // Maximum scale allowed (zoomed in)
            initialScale: zoomedIn ? 2 : 1 ,
            disabled: { MouseEvent: true } 
          }} 
          ref={transformWrapperRef}
          onTransformed={handleWheel} >
 {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div key={index} onDoubleClick={()=>{setZoomedIn(!zoomedIn); if(zoomedIn){resetTransform()}}} className="page" style={{cursor: zoomedIn ? "zoom-out" : "zoom-in",height:"fit-content"}}>
<TransformComponent wrapperClass="h-100" onClick={(e) => zoomIntoArea(e.nativeEvent.offsetX, e.nativeEvent.offsetY)} >

          <img  src={page} alt="" height={875} style={{ transitionDuration:"3.5s"}} />

          </TransformComponent>

        </div>
 )}
        </TransformWrapper>

      ))}
    </Turn>

  );
};
export default PdfViewer;