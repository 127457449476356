import React from "react";
import ImageUtil from "../../utilities/ImageUtil";

function MainImage(props) {
  const { sample } = props;
  const image = ImageUtil.transform(sample.featureImage);

  const handlePdfDownload = async (fileName) => {
    const response = await fetch(ImageUtil.transform(sample.path));
    response.arrayBuffer().then(function (buffer) {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div
      className="h-75 rounded d-flex justify-content-centre pt-2"
      onClick={() => handlePdfDownload(`${sample.month}'${sample.year}`)}
    >
      <div className=" rounded mx-auto my-auto buzz-main-image">
        <img
          title="Download Pdf"
          className="w-100 image-top-border border border-dark main-feature-image"
          role="button"
          src={image}
          alt=""
        />
        <div className="bg-white text-center p-2 text-bottom-border border border-dark">
        {`${sample.month.charAt(0).toUpperCase()}${sample.month.slice(1).toLowerCase()}`} {sample.year}
        </div>
      </div>
    </div>
  );
}

export default MainImage;
