import TokenService from "./token.service";
import axios from "../config/axios";

class BirthdayTemplateBankService {
  uploadTemplate = async (payload) => {
    const response = await axios.post(
      `/birthdayPost/uploadBirthdayTemplate`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  generatePreview = async (payload) => {
    const response = await axios.post(
      `/birthdayPost/generatePreview`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  updateTemplate = async (payload) => {
    const response = await axios.post(
      `/birthdayPost/updateBirthdayTemplate`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  deleteTemplate = async (payload) => {
    const response = await axios.post(
      `/birthdayPost/deleteBirthdayTemplate`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  getTemplates = async (pageNo, templatesPerPage) => {
    const response = await axios.get(
      `/birthdayPost/showBirthdayTemplates?page=${pageNo}&limit=${templatesPerPage}`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  getPPT = async (templateIds) => {
    
    const response = await axios.get(`/birthdayPost/getBirthdayPPT?templateIds=${templateIds}`,
     {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  getUserImage = async (empId) => {
    const response = await axios.get(`/birthdayPost/getUserImage?empId=${empId}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  getBirthdayPosts = async (pageNo) => {
    const response = await axios.get(
      `/birthdayPost?page=${pageNo}&limit=5000`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );

    return response;
  };

  updateBirthdayPost = async (payload, id) => {
    const response = await axios.post(`/birthdayPost/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  zoomUserImage = async (payload, id) => {
    const response = await axios.post(`/birthdayPost/zoom/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  createBirthdayPost = async () => {
    const response = await axios.post(`/birthdayPost/create`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
  getAllActivityLogs = async () => {
    const response = await axios.get(`/birthdayPost/activity`, {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    });

    return response;
  };
}

export default new BirthdayTemplateBankService();
