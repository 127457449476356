const tabledata = [
    {
        hold: ''
    },
    {
        hold: ''
    },
    {
        hold: ''
    },
    {
        hold: ''
    },
    {
        hold: ''
    },
    {
        hold: ' '
    }
];

export default tabledata;